/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  InputAdornment, Grid, Table, TableContainer, TableHead, TableRow, TableCell, Button, TableBody
} from '@material-ui/core';
import { toast } from 'react-toastify';
import { Delete } from '@material-ui/icons';

import {
  InputDate,
  InputText,
  InputRadio,
  InputRadioGroup,
  InputNumber,
  InputTextarea,
  InputCheckbox,
  InputCheckboxGroup
} from '@aldridge/aldg-data-components';
import { ModalBox, useModalState } from '@aldridge/aldg-confirm';
import { firestore } from '../firebase';
import { UserContext } from '../providers/UserProvider';

const CablesList = (props) => {
  const { Cables, onSelect, removeRecord } = props;

  return (
    <>
      <Grid container spacing={3}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Contents</TableCell>
                <TableCell>Cable ID</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {Cables.map((row) => (
                <TableRow onClick={() => onSelect(row.id)} key={row.id}>
                  <TableCell>{row.data.Contents}</TableCell>
                  <TableCell>{row.data.CableID}</TableCell>
                  <TableCell align="right"><Delete onClick={(e) => { e.stopPropagation(); removeRecord(row); }} /></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </>
  );
};

CablesList.propTypes = {
  Cables: PropTypes.arrayOf(PropTypes.any),
  onSelect: PropTypes.func,
  removeRecord: PropTypes.func.isRequired
};
CablesList.defaultProps = {
  Cables: [],
  onSelect: () => { }
};

const CablesForm = (props) => {
  const {
    transaction, setTransaction, index, closeRecord
  } = props;
  const user = useContext(UserContext);
  const [record, setRecord] = useState({
    id: '',
    ManholeID: transaction.id,
    path: '',
    collection: 'Cables',
    CreatedBy: '',
    CreatedDate: '',
    ModifiedBy: '',
    ModifiedDate: '',
    data: {
      Contents: '',
      CableID: '',
      CableConditionBad: '',
      CableConditionIssues: '',
      CableType: '',
      CableComments: '',
    }
  });

  useEffect(() => {
    let mounted = true;
    if (index !== '') {
      firestore
        .collection(`Manholes/${transaction.id}/Cables`)
        .doc(index)
        .onSnapshot((doc) => {
          if (doc.exists && mounted) {
            setRecord(doc.data());
          } else {
            setRecord({ ...record, id: doc.id, path: `Manholes/${transaction.id}/Cables/${doc.id}` });
          }
        });
    } else {
      const docRef = firestore.collection(`Manholes/${transaction.id}/Cables`).doc();
      if (mounted) setRecord({ ...record, id: docRef.id, path: `Manholes/${transaction.id}/Cables/${docRef.id}` });
    }
    return () => (mounted = false);
  }, [index]);

  const onChange = (event, name, displayFromTypeahead) => {
    try {
      const id = name || event.target.name;
      const value = typeof name !== 'undefined' ? event : event.target.value;
      const changedRecord = { ...record };
      changedRecord.data[id] = value;
      if (typeof displayFromTypeahead !== 'undefined') {
        changedRecord.data[`${id}Display`] = displayFromTypeahead;
      }
      if (changedRecord.CreatedDate === '') {
        changedRecord.CreatedBy = '';// user.email;
        changedRecord.CreatedDate = new Date().toJSON();
      }
      changedRecord.ModifiedBy = '';// user.email;
      changedRecord.ModifiedDate = new Date().toJSON();
      setRecord(changedRecord);
    } catch (err) {
      toast.error(err.message);
    }
  };

  const addCables = () => {
    firestore
      .collection(`Manholes/${record.ManholeID}/Cables`)
      .doc(`${record.id}`)
      .set(record, { merge: true })
      .then((res) => {
        closeRecord();
      });
  };
  const displayBox = (truthyVal) => truthyVal ? 'none' : 'flex';
  return (
    <>
      <div className="subcollectionButtons">
        <Button variant="contained" color="primary" style={{ marginRight: '5px' }} onClick={addCables}>{(index === '') ? 'Add' : 'Update'} Cables</Button>
        <Button variant="contained" onClick={closeRecord}>Close</Button>
      </div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <InputRadioGroup name="Contents" value={record.data.Contents || ''} label="What's in the Conduit?" onChange={onChange}>
            <InputRadio value="Alarm Cable" label="Alarm Cable" />
            <InputRadio value="Transmission" label="Transmission" />
            <InputRadio value="Plumbing" label="Plumbing" />
            <InputRadio value="Fiber" label="Fiber" />
          </InputRadioGroup>
        </Grid>
        <Grid item xs={6}>
          <InputText onChange={onChange} hidden={record.data.Contents !== 'Transmission'} value={record.data.CableID || ''} name="CableID" label="Cable ID" />
        </Grid>
        <Grid item xs={6}>
          <InputRadioGroup name="CableType" hidden={record.data.Contents !== 'Transmission'} value={record.data.CableType || ''} label="Cable Type" onChange={onChange}>
            <InputRadio value="BICC" label="BICC" />
            <InputRadio value="Lead" label="Lead" />
          </InputRadioGroup>
        </Grid>
        <Grid container spacing={3}>



          <Grid item xs={6}>
            <InputRadioGroup name="CableConditionBad" value={record.data.CableConditionBad || ''} label="Is the Cable Condition Bad?" onChange={onChange}>
              <InputRadio value="Yes" label="Yes" />
              <InputRadio value="No" label="No" />
            </InputRadioGroup>
          </Grid>
          <Grid item xs={6}>
            <InputCheckboxGroup label="What has Bad Conditions?" name="CableConditionIssues" hidden={!(record.data.CableConditionBad === 'Yes')} onChange={onChange} value={record.data.CableConditionIssues}>
              <InputCheckbox label="Leak" value="Leak" />
              <InputCheckbox label="Swollen" value="Swollen" />
              <InputCheckbox label="Cracked" value="Cracked" />
              <InputCheckbox label="Mud" value="Mud" />
              <InputCheckbox label="Disconnected" value="Disconnected" />
            </InputCheckboxGroup>
          </Grid>
        </Grid>


        <Grid item xs={12}>
          <InputTextarea name="CableComments" value={record.data.CableComments} label="Cable Comments" onChange={onChange} />
        </Grid>
      </Grid>
    </>
  );
};

CablesForm.propTypes = {
  transaction: PropTypes.objectOf(PropTypes.any),
  setTransaction: PropTypes.func.isRequired,
  index: PropTypes.string.isRequired,
  closeRecord: PropTypes.func.isRequired,
};
CablesForm.defaultProps = {
  transaction: {}
};

const Cables = (props) => {
  const {
    transaction, setTransaction, setShowButtons
  } = props;
  const [view, setView] = useState('table');
  const [recordId, setRecordId] = useState('');
  const [cables, setCables] = useState([]);

  const handleSelect = (rid) => {
    setRecordId(rid);
    setView('form');
    setShowButtons(false);
  };
  const handleNew = () => {
    setView('form');
    setShowButtons(false);
  };
  const closeRecord = () => {
    setView('table');
    setRecordId('');
    setShowButtons(true);
  };
  useEffect(() => {
    firestore.collection(`Manholes/${transaction.id}/Cables`).onSnapshot((res) => {
      const allCables = [];
      res.forEach((doc) => allCables.push(doc.data()));
      setCables(allCables);
    });
  }, [transaction]);

  const removeRecord = async (r) => {
    await firestore
      .collection(`Manholes/${r.ManholeID}/Cables`)
      .doc(r.id)
      .delete();
    toast.success('Cable Deleted.');
  };
  const [modal, setModalOpen, setModalProps] = useModalState({
    open: false,
    title: 'Delete Cable',
    message: 'Would you like to delete this cable?',
    record: {},
    buttons: [{
      text: 'Delete',
      onClick: (record, { close }) => {
        removeRecord(record);
        close();
      }
    }, {
      text: 'Cancel',
      onClick: (record, { close }) => {
        close()
      }
    }],
    onBackdropClick: ({ close }) => {
      close()
    }
  });
  const handleDelete = (record) => {
    setModalProps({ record })
    setModalOpen(true);
  };

  const selectView = () => {
    if (view === 'table') {
      return (
        <>
          <div style={{
            width: '100%', display: 'flex', justifyContent: 'flex-end', marginBottom: '16px'
          }}
          >
            <Button variant="contained" color="primary" onClick={handleNew}>Add Cable</Button>
          </div>
          <CablesList
            Cables={cables}
            onSelect={handleSelect}
            removeRecord={handleDelete}
          />
        </>
      );
    }
    return (
      <CablesForm
        index={recordId}
        transaction={transaction}
        setTransaction={setTransaction}
        closeRecord={closeRecord}
      />
    );
  };
  return (
    <>
      <ModalBox modal={modal} />
      {selectView()}
    </>

  );
};

Cables.propTypes = {
  transaction: PropTypes.objectOf(PropTypes.any),
  setTransaction: PropTypes.func,
  setShowButtons: PropTypes.func
};
Cables.defaultProps = {
  transaction: {},
  setTransaction: () => { },
  setShowButtons: () => { }
};
export default Cables;
