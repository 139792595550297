/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  InputAdornment,
  Grid,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Button,
  TableBody,
} from '@material-ui/core';
import { toast } from 'react-toastify';
import { Delete } from '@material-ui/icons';

import { InputText, InputSelect } from '@aldridge/aldg-data-components';
import { ModalBox, useModalState } from '@aldridge/aldg-confirm';
import { firestore } from '../firebase';

const UserManagementList = (props) => {
  const { users, onSelect, removeRecord } = props;

  return (
    <>
      <Grid container spacing={3}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Group</TableCell>
                <TableCell> </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((row) => (
                <TableRow
                  style={{ cursor: 'pointer' }}
                  hover
                  onClick={() => onSelect(row.id)}
                  key={row.id}
                >
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.email}</TableCell>
                  <TableCell>{row.phone}</TableCell>
                  <TableCell>{row.group}</TableCell>
                  <TableCell align="right">
                    <Delete
                      onClick={(e) => {
                        e.stopPropagation();
                        removeRecord(row);
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </>
  );
};

UserManagementList.propTypes = {
  users: PropTypes.arrayOf(PropTypes.any),
  onSelect: PropTypes.func,
  removeRecord: PropTypes.func.isRequired,
};
UserManagementList.defaultProps = {
  users: [],
  onSelect: () => { },
};

const UserManagementForm = (props) => {
  const { docId, setDocId, closeRecord } = props;
  const [record, setRecord] = useState({
    name: '',
    email: '',
    phone: '+1',
    group: '',
  });

  useEffect(() => {
    if (docId !== '') {
      firestore
        .collection('Users')
        .doc(docId)
        .onSnapshot((snap) => {
          if (snap.exists) {
            const r = snap.data();
            setRecord(r);
          } else {
            setDocId('');
          }
        });
    }
  }, []);

  const onChange = (event, name, displayFromTypeahead) => {
    try {
      const id = name || event.target.name;
      const value = typeof name !== 'undefined' ? event : event.target.value;
      const changedRecord = { ...record };
      changedRecord[id] = value;
      setRecord(changedRecord);
    } catch (err) {
      toast.error(err.message);
    }
  };

  const addReservoir = () => {
    const updatedRecord = { ...record };
    if (updatedRecord.phone.substr(0, 2) !== '+1') {
      toast.error("Please re-add the +1 at the beginning of the phone number.");
      return
    }
    if (
      updatedRecord.name !== '' &&
      updatedRecord.email !== '' &&
      updatedRecord.phone !== '' &&
      (updatedRecord.group !== '' || updatedRecord.group !== '--Select--')
    ) {
      const dId = (() => {
        if (updatedRecord.id === '' || typeof updatedRecord.id === 'undefined') {
          const dr = firestore.collection('Users').doc();
          return dr.id;
        }
        return updatedRecord.id;

      })();
      updatedRecord.id = dId;
      const docRef = firestore.collection('Users').doc(dId);
      docRef.set(updatedRecord, { merge: true }).then((res) => {
        closeRecord();
      });
    } else {
      toast.error(
        'All fields are required. Please fill out all fields to add a third party user.'
      );
    }
  };
  const closeReservoir = () => {
    setRecord({
      id: '',
      name: '',
      email: '',
      phone: '+1',
      group: '',
    });
    closeRecord();
  };
  const userAccessLevel = [
    { label: 'User', value: 'App-Manhole-U' },
    { label: 'Admin', value: 'App-Manhole-A' },
    { label: 'Viewer Access Only', value: 'App-Manhole-V' },
  ];
  return (
    <>
      <div
        style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}
      >
        <Button
          variant="contained"
          color="primary"
          style={{ marginRight: '5px' }}
          onClick={addReservoir}
        >
          {docId === '' ? 'Add' : 'Update'} User
        </Button>
        <Button variant="contained" onClick={closeReservoir}>
          Close
        </Button>
      </div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <InputText
            onChange={onChange}
            value={record.name || ''}
            name="name"
            label="User Name"
            required
          />
        </Grid>
        <Grid item xs={12}>
          <InputText
            onChange={onChange}
            value={record.email || ''}
            name="email"
            label="User Email"
            required
          />
        </Grid>
        <Grid item xs={12}>
          <InputText
            onChange={onChange}
            value={record.phone || ''}
            name="phone"
            label="User Work Phone"
            required
          />
        </Grid>
        <Grid item xs={12}>
          <InputSelect
            name="group"
            value={record.group || ''}
            label="User Access Level"
            onChange={onChange}
            required
            options={userAccessLevel}
          />
        </Grid>
      </Grid>
    </>
  );
};

UserManagementForm.propTypes = {
  setDocId: PropTypes.func.isRequired,
  docId: PropTypes.string.isRequired,
  closeRecord: PropTypes.func.isRequired,
};
UserManagementForm.defaultProps = {};

const UserManagement = (props) => {
  const [view, setView] = useState('table');
  const [recordId, setRecordId] = useState('');

  const handleSelect = (rid) => {
    setRecordId(rid);
    setView('form');
  };
  const closeRecord = () => {
    setView('table');
    setRecordId('');
  };

  const [users, setUsers] = useState([]);

  const removeRecord = (r) => {
    firestore.collection('Users').doc(r.idx).delete();
  };

  const [modal, setModalOpen, setModalProps] = useModalState({
    open: false,
    title: 'Delete User?',
    message: 'Do you want to delete this user and all their accesss?',
    buttons: [
      {
        text: 'Delete',
        color: 'primary',
        onClick: async (record, { close }) => {
          try {
            await firestore
              .collection('Users')
              .doc(record.id)
              .delete()
              .then((res) => {
                toast.success('User Deleted.');
              })
              .catch((err) => {
                console.log(err);
              });
          } catch (err) {
            toast.error(err.toString());
          }
          close();
        },
      },
      {
        text: 'Close',
        onClick: (record, { close }) => {
          removeRecord(record);
          close();
        },
      },
    ],
    onBackdropClick: ({ close }) => {
      close();
    },
  });
  const handleDeleteClick = (row) => {
    setModalProps({ record: row });
    setModalOpen(true);
  };

  useEffect(() => {
    firestore
      .collection('Users')
      .where('group', 'not-in', ['App-Global-A', 'App-Global-U'])
      .onSnapshot((querySnapshot) => {
        const allUsers = [];
        querySnapshot.forEach((doc) => {
          allUsers.push(doc.data());
        });
        setUsers(allUsers);
      });
  }, []);

  const selectView = () => {
    if (view === 'table') {
      return (
        <>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
              marginBottom: '16px',
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() => setView('form')}
            >
              Add Third Party User
            </Button>
          </div>
          <UserManagementList
            users={users}
            onSelect={handleSelect}
            removeRecord={handleDeleteClick}
          />
        </>
      );
    }
    return (
      <UserManagementForm
        docId={recordId}
        setDocId={setRecordId}
        closeRecord={closeRecord}
      />
    );
  };
  return (
    <>
      <ModalBox modal={modal} />
      <div style={{ textAlign: 'center' }}>
        <h4>Third Party User Management Only</h4>
        <h6>
          If you need an Aldridge Employee added for access, please contact the
          Helpdesk using the{' '}
          <a
            href="https://aldridge.samanage.com/catalog_items/1299531-application-access-request/service_requests/new"
            rel="noreferrer"
            target="_blank"
          >
            Request Form
          </a>
        </h6>
      </div>
      {selectView()}
    </>
  );
};

UserManagement.propTypes = {};
UserManagement.defaultProps = {};

export default UserManagement;
