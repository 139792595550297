/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  InputAdornment, Grid, Table, TableContainer, TableHead, TableRow, TableCell, Button, TableBody
} from '@material-ui/core';
import { toast } from 'react-toastify';
import { Delete } from '@material-ui/icons';

import {
  InputDate,
  InputText,
  InputRadio,
  InputRadioGroup,
  InputNumber,
  InputTextarea,
  InputCheckbox,
  InputCheckboxGroup,
  InputAutocomplete
} from '@aldridge/aldg-data-components';
import { ModalBox, useModalState } from '@aldridge/aldg-confirm';
import { firestore } from '../firebase';
import { UserContext } from '../providers/UserProvider';

const ConduitsList = (props) => {
  const { conduits, onSelect, removeRecord } = props;

  return (
    <>
      <Grid container spacing={3}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Position</TableCell>
                <TableCell>What&apos;s in the Conduit</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {conduits.map((row) => (
                <TableRow onClick={() => onSelect(row.id)} key={row.id}>
                  <TableCell>{row.data.ConduitPosition}</TableCell>
                  <TableCell>{row.data.Contents === 'No' ? row.data.CablesDisplay : 'Empty'}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </>
  );
};

ConduitsList.propTypes = {
  conduits: PropTypes.arrayOf(PropTypes.any),
  onSelect: PropTypes.func,
  removeRecord: PropTypes.func.isRequired
};
ConduitsList.defaultProps = {
  conduits: [],
  onSelect: () => { }
};

const ConduitsForm = (props) => {
  const {
    transaction, setTransaction, index, closeRecord, ManholeID, DuctBankID
  } = props;
  const user = useContext(UserContext);
  const [record, setRecord] = useState({
    id: '',
    ManholeID,
    DuctBankID,
    path: '',
    collection: 'Conduits',
    CreatedBy: '',
    CreatedDate: '',
    ModifiedBy: '',
    ModifiedDate: '',
    data: {
      ConduitPosition: '',
      Contents: 'Empty',
      CableID: '',
      CableConditionBad: '',
      CableConditionIssues: '',
      CableType: '',
      Joint: '',
      JointType: '',
      JointConditionBad: '',
      ConduitComments: '',
    }
  });

  useEffect(() => {
    let mounted = true;
    if (index !== '') {
      firestore
        .collection(`Manholes/${record.ManholeID}/DuctBanks/${record.DuctBankID}/Conduits`)
        .doc(index)
        .onSnapshot((doc) => {
          if (mounted) {
            if (doc.exists) {
              setRecord(doc.data());
            }
          } else if (mounted) {
            if (doc.exists) {
              setRecord({ ...record, id: doc.id, path: `Manholes/${transaction.id}/Conduits/${doc.id}` });
            }
          }
        });
    }
    return () => (mounted = false);
  }, [index]);

  const onChange = (event, name, displayFromTypeahead) => {
    try {
      const id = name || event.target.name;
      const value = typeof name !== 'undefined' ? event : event.target.value;
      const changedRecord = { ...record };
      changedRecord.data[id] = value;
      if (typeof displayFromTypeahead !== 'undefined') {
        changedRecord.data[`${id}Display`] = displayFromTypeahead;
      }
      if (changedRecord.CreatedDate === '') {
        changedRecord.CreatedBy = '';// user.email;
        changedRecord.CreatedDate = new Date().toJSON();
      }
      changedRecord.ModifiedBy = '';// user.email;
      changedRecord.ModifiedDate = new Date().toJSON();
      setRecord(changedRecord);
    } catch (err) {
      toast.error(err.message);
    }
  };

  const addConduits = () => {
    firestore
      .collection(`Manholes/${record.ManholeID}/DuctBanks/${record.DuctBankID}/Conduits`)
      .doc(`${record.id}`)
      .set(record, { merge: true })
      .then((res) => {
        closeRecord();
      });
  };
  return (
    <>
      <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
        <Button variant="contained" color="primary" style={{ marginRight: '5px' }} onClick={addConduits}>{(index === '') ? 'Add' : 'Update'} Conduits</Button>
        <Button variant="contained" onClick={closeRecord}>Close</Button>
      </div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <InputText onChange={onChange} value={record.data.ConduitPosition || ''} name="ConduitPosition" label="Conduit Position" disabled />
        </Grid>
        <Grid item xs={6}>
          <InputRadioGroup name="Contents" value={record.data.Contents || ''} label="Is The Conduit Empty?" onChange={onChange}>
            <InputRadio value="Empty" label="Empty" />
            <InputRadio value="No" label="No" />
          </InputRadioGroup>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid item xs={12}>
                <InputAutocomplete
                  name="Cables"
                  label="What Cable is in the Conduit?"
                  onChange={onChange}
                  value={record.data.Cables || []}
                  optionDisplay={['option_CableID', ' - ', 'option_Contents']}
                  valueKey="id"
                  collection={`Manholes/${record.ManholeID}/Cables`}
                  required
                  enterprise={false}
                  hidden={!(record.data.Contents === 'No')}
                  firestore={firestore}
                />
              </Grid>
            </Grid>
          </Grid>

        </Grid>

        <Grid item xs={12}>
          <InputTextarea name="ConduitComments" value={record.data.ConduitComments} label="Conduit Comments" onChange={onChange} />
        </Grid>
      </Grid>
    </>
  );
};

ConduitsForm.propTypes = {
  transaction: PropTypes.objectOf(PropTypes.any),
  setTransaction: PropTypes.func.isRequired,
  index: PropTypes.string.isRequired,
  closeRecord: PropTypes.func.isRequired,
  ManholeID: PropTypes.string.isRequired,
  DuctBankID: PropTypes.string.isRequired,
};
ConduitsForm.defaultProps = {
  transaction: {}
};

const Conduits = (props) => {
  const {
    transaction, setTransaction, conduits, ManholeID, DuctBankID, setShowButtons
  } = props;
  const [view, setView] = useState('table');
  const [recordId, setRecordId] = useState('');
  // const [conduits, setConduits] = useState([]);

  const handleSelect = (rid) => {
    setRecordId(rid);
    setView('form');
    setShowButtons(false);
  };
  const handleNew = () => {
    setView('form');
    setShowButtons(false);
  };
  const closeRecord = () => {
    setView('table');
    setRecordId('');
    setShowButtons(true);
  };

  const removeRecord = async (r) => {
    await firestore
      .collection(`Manholes/${r.ManholeID}/Conduits`)
      .doc(r.id)
      .delete();
    toast.success('Conduit Deleted.');
  };
  const [modal, setModalOpen, setModalProps] = useModalState({
    open: false,
    title: 'Delete Cable',
    message: 'Would you like to delete this cable?',
    record: {},
    buttons: [{
      text: 'Delete',
      onClick: (record, { close }) => {
        removeRecord(record);
        close();
      }
    }, {
      text: 'Cancel',
      onClick: (record, { close }) => {
        close()
      }
    }],
    onBackdropClick: ({ close }) => {
      close()
    }
  });
  const handleDelete = (record) => {
    setModalProps({ record })
    setModalOpen(true);
  };
  const selectView = () => {
    if (view === 'table') {
      return (
        <>
          <ConduitsList
            conduits={conduits}
            onSelect={handleSelect}
            removeRecord={handleDelete}
          />
        </>
      );
    }
    return (
      <ConduitsForm
        index={recordId}
        transaction={transaction}
        setTransaction={setTransaction}
        ManholeID={ManholeID}
        DuctBankID={DuctBankID}
        closeRecord={closeRecord}
      />
    );
  };
  return (
    <>
      <ModalBox modal={modal} />
      {selectView()}
    </>

  );
};

Conduits.propTypes = {
  transaction: PropTypes.objectOf(PropTypes.any),
  conduits: PropTypes.arrayOf(PropTypes.any),
  setTransaction: PropTypes.func,
  ManholeID: PropTypes.string.isRequired,
  DuctBankID: PropTypes.string.isRequired,
  setShowButtons: PropTypes.func
};
Conduits.defaultProps = {
  transaction: {},
  conduits: [],
  setTransaction: () => { },
  setShowButtons: () => { }
};

export default Conduits;
