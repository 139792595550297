/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useRef, useEffect, useState } from 'react';
import '@aldridge/aldg-pdf/dist/index.css'
import { PepcoPdf } from '@aldridge/aldg-pdf';
import html2pdf from 'html2pdf.js'
import Button from '@material-ui/core/Button'
import { toast } from 'react-toastify';
import Loader from 'react-loader-spinner';
import { firestore, storage } from '../firebase';

const TransmissionPDF = (props) => {
  const { transaction, hideUpload } = props;
  const ref = useRef();
  const [uploadingPDF, setUploadingPDF] = useState(false);
  const [pdfTransaction, setPdfTransaction] = useState(transaction);

  const click = () => {
    const opt = {
      margin: 1,
      filename: `${transaction.data.General.GridNumber}_${transaction.data.General.CrewLead}_${transaction.data.General.InspectionDate_Date}.pdf`,
    };
    const f = html2pdf().from(ref.current).set(opt).output("blob").then(res => {
      setUploadingPDF(true);
      const storageRef = storage.ref();
      const pdfF = storageRef.child(
        `${transaction.data.General.GridNumber}/${transaction.data.General.CrewLead}/${transaction.data.General.InspectionDate_Date}/${transaction.data.General.GridNumber}_${transaction.data.General.CrewLead}_${transaction.data.General.InspectionDate_Date}.pdf`
      );
      const task = pdfF.put(res);
      task.then(() => {
        toast.success('PDF Uploaded')
        setUploadingPDF(false);
      }).catch(err => {
        toast.success(`PDF Failed to Upload: ${err.message}`)
        setUploadingPDF(false);
      })
    });
  }

  useEffect(() => {
    const updatedPdfTransaction = { ...transaction };
    const getCables = async () => {
      const r = await firestore.collection(`Manholes/${transaction.id}/Cables`).get();
      const records = [];
      r.forEach(doc => records.push(doc.data()));
      return records;
    }
    const getJoints = async () => {
      const r = await firestore.collection(`Manholes/${transaction.id}/Joints`).get();
      const records = [];
      r.forEach(doc => records.push(doc.data()));
      return records;
    }
    const getReservoirs = async () => {
      const r = await firestore.collection(`Manholes/${transaction.id}/Reservoirs`).get();
      const records = [];
      r.forEach(doc => records.push(doc.data()));
      return records;
    }
    const getGauges = async () => {
      const r = await firestore.collection(`Manholes/${transaction.id}/Gauges`).get();
      const records = [];
      r.forEach(doc => records.push(doc.data()));
      return records;
    }
    const getDucts = async () => {
      const r = await firestore.collection(`Manholes/${transaction.id}/DuctBanks`).get();
      const records = [];
      for (let i = 0; i < r.docs.length; i++) {
        const duct = r.docs[i].data();
        const conduits = [];
        // eslint-disable-next-line no-await-in-loop
        const c = await firestore.collection(`Manholes/${transaction.id}/DuctBanks/${duct.id}/Conduits`).get();
        c.forEach(doc2 => conduits.push(doc2.data()));
        duct.Conduits = conduits;
        records.push(duct);
      }
      return records;
    }
    Promise.all([
      getCables(), getJoints(), getReservoirs(), getGauges(), getDucts()
    ]).then(res => {
      const [
        cables, joints, reservoirs, gauges, ducts
      ] = res;
      updatedPdfTransaction.Cables = cables;
      updatedPdfTransaction.Joints = joints;
      updatedPdfTransaction.Reservoirs = reservoirs;
      updatedPdfTransaction.Gauges = gauges;
      updatedPdfTransaction.Ducts = ducts;
      setPdfTransaction(updatedPdfTransaction);
    })
  }, [transaction]);
  return (
    <>
      {uploadingPDF ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            flexDirection: 'column',
          }}
        >
          <Loader type="TailSpin" />
          <div>Uploading Files...</div>
        </div>
      ) : null}
      {hideUpload ? null : <Button color="primary" variant="contained" onClick={click}>Upload PDF to Storage</Button>}
      <PepcoPdf
        ref={ref}
        doc={pdfTransaction}
      />
    </>
  );
}

export default TransmissionPDF;