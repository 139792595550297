/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  InputAdornment,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  makeStyles,
  Card,
  CardHeader,
  CardContent,
  Box,
} from '@material-ui/core';
import { displayRaw } from '@material-ui/system';
import {
  InputDate,
  InputAddress,
  InputText,
  InputNumber,
  InputRadio,
  InputRadioGroup,
  InputCheckbox,
  InputCheckboxGroup,
  InputTextarea,
} from '@aldridge/aldg-data-components';
import { UserContext } from '../providers/UserProvider';
import { config } from '../firebase';

const TransmissionHoleGeneral = (props) => {
  const user = useContext(UserContext);
  const { transaction, onChange } = props;
  const AccessToManhole = transaction.data.General.AccessManhole === 'Yes';
  const ManholeAllowedToBeInspected =
    AccessToManhole &&
    transaction.data.Characteristics.InScope === 'Yes' &&
    transaction.data.Characteristics.IsClean === 'Yes';

  const accordionClass = makeStyles({
    root: {
      border: '1px solid #ccc',
      '&:not(:last-child)': {
        borderBottom: 0,
      },
      '&$expanded': {
        border: '1px solid #2a56a7',
        margin: '8px 0',
      },
    },
    expanded: {},
  })();
  const accordionSummaryClass = makeStyles({
    root: {
      backgroundColor: '#eee',
      boxShadow: 'inset 0px -19px 4px -19px #aaa',
      '&$expanded': {
        maxHeight: '48px',
        minHeight: '48px',
        backgroundColor: 'rgb(59, 120, 231)',
        color: 'white',
        // border: '1px solid #ccc',
      },
    },
    expanded: {},
  })();
  const accordionDetailsClass = makeStyles({
    root: {
      flexDirection: 'column',
    },
  })();
  const cardClass = makeStyles({
    root: {
      width: '100%',
      margin: '8px 0',
    },
  })();
  const cardHeaderClass = makeStyles({
    root: {
      backgroundColor: '#eee',
    },
    title: {
      fontSize: '1rem',
    },
  })();

  const displayBox = (truthyVal) => (truthyVal ? 'none' : 'flex');

  return (
    <>
      <Accordion classes={accordionClass}>
        <AccordionSummary classes={accordionSummaryClass}>
          <div>General</div>
        </AccordionSummary>
        <AccordionDetails classes={accordionDetailsClass}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <InputDate
                type="datetime-local"
                onChange={onChange}
                value={transaction.data.General.InspectionDate || ''}
                name="General.InspectionDate"
                label="Inspection Date"
              />
            </Grid>
            <Grid item xs={6}>
              <InputText
                onChange={onChange}
                value={transaction.data.General.CrewLead || ''}
                name="General.CrewLead"
                label="Crew Lead"
                disabled={!user.admin}
              />
            </Grid>
            <Grid item xs={6}>
              <InputText
                onChange={onChange}
                value={transaction.data.General.GridNumber || ''}
                name="General.GridNumber"
                label="Grid Number"
              />
            </Grid>
            <Grid item xs={6}>
              <InputText
                onChange={onChange}
                value={transaction.data.General.LegacyGridNumber || ''}
                name="General.LegacyGridNumber"
                label="Legacy Grid Number"
              />
            </Grid>
            <Grid item xs={12}>
              <InputAddress
                record={transaction}
                onChange={onChange}
                value={transaction.data.General.Location || ''}
                name="General.Location"
                label="Location (GPS)"
                config={config}
              />
            </Grid>
            <Grid item xs={6}>
              <InputRadioGroup
                name="General.AccessManhole"
                value={transaction.data.General.AccessManhole || ''}
                label="Can You Access the Manhole?"
                onChange={onChange}
              >
                <InputRadio value="Yes" label="Yes" />
                <InputRadio value="No" label="No" />
              </InputRadioGroup>
            </Grid>
            <Grid item xs={6}>
              <InputCheckboxGroup
                label="What are the Access Issues?"
                name="General.AccessIssues"
                hidden={!(transaction.data.General.AccessManhole === 'No')}
                onChange={onChange}
                value={transaction.data.General.AccessIssues || ''}
              >
                <InputCheckbox
                  label="Construction Zone"
                  value="Construction Zone"
                />
                <InputCheckbox
                  label="Missing/Paved Over"
                  value="Missing/Paved Over"
                />
                <InputCheckbox
                  label="No Parking Signs"
                  value="No Parking Signs"
                />
                <InputCheckbox label="Traffic/Night" value="Traffic/Night" />
                <InputCheckbox label="Special Access" value="Special Access" />
              </InputCheckboxGroup>
            </Grid>
            <Grid item xs={12}>
              <InputTextarea
                label="Notes/Comments"
                name="General.GeneralComments"
                onChange={onChange}
                value={transaction.data.General.GeneralComments}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion classes={accordionClass} hidden={!AccessToManhole}>
        <AccordionSummary classes={accordionSummaryClass}>
          Characteristics
        </AccordionSummary>
        <AccordionDetails classes={accordionDetailsClass}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <InputRadioGroup
                name="Characteristics.InScope"
                value={transaction.data.Characteristics.InScope || ''}
                label="In Scope"
                onChange={onChange}
              >
                <InputRadio value="Yes" label="Yes" />
                <InputRadio value="No" label="No" />
              </InputRadioGroup>
            </Grid>
            <Grid item xs={6}>
              <InputRadioGroup
                name="Characteristics.IsClean"
                value={transaction.data.Characteristics.IsClean || ''}
                label="Is Clean"
                onChange={onChange}
              >
                <InputRadio value="Yes" label="Yes" />
                <InputRadio value="No" label="No" />
              </InputRadioGroup>
            </Grid>
            <Box component={Grid} item display="none">
              <span>ZZ</span>
            </Box>
            <Box
              component={Grid}
              display={displayBox(
                !(
                  transaction.data.Characteristics.InScope === 'Yes' &&
                  transaction.data.Characteristics.IsClean === 'Yes'
                )
              )}
              item
              xs={12}
            >
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <InputNumber
                    name="Characteristics.ManholeLength"
                    value={transaction.data.Characteristics.ManholeLength}
                    label="Manhole Size Length"
                    onChange={onChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputNumber
                    name="Characteristics.ManholeWidth"
                    value={transaction.data.Characteristics.ManholeWidth}
                    label="Manhole Size Width"
                    onChange={onChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputNumber
                    name="Characteristics.DepthClearance"
                    value={transaction.data.Characteristics.DepthClearance}
                    label="Depth Clearance"
                    onChange={onChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputNumber
                    name="Characteristics.CeilingToSurface"
                    value={transaction.data.Characteristics.CeilingToSurface}
                    label="Ceiling to Surface"
                    onChange={onChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputRadioGroup
                    name="Characteristics.ProperlyLabelled"
                    value={
                      transaction.data.Characteristics.ProperlyLabelled || ''
                    }
                    label="Is the Manhole Properly Labelled?"
                    onChange={onChange}
                  >
                    <InputRadio value="No Label" label="No Label" />
                    <InputRadio value="Wrong Label" label="Wrong Label" />
                    <InputRadio value="Right Label" label="Right Label" />
                  </InputRadioGroup>
                </Grid>
                <Grid item xs={6}>
                  <InputRadioGroup
                    name="Characteristics.BelowSeaLevel"
                    value={
                      transaction.data.Characteristics.BelowSeaLevel || ''
                    }
                    label="Manhole Makes Continuous Water?"
                    onChange={onChange}
                  >
                    <InputRadio value="Yes" label="Yes" />
                    <InputRadio value="No" label="No" />
                  </InputRadioGroup>
                </Grid>
                <Grid item xs={6}>
                  <InputRadioGroup
                    name="Characteristics.WaterOnManhole"
                    value={
                      transaction.data.Characteristics.WaterOnManhole || ''
                    }
                    label="Water on Manhole"
                    onChange={onChange}
                  >
                    <InputRadio value="Yes" label="Yes" />
                    <InputRadio value="No" label="No" />
                  </InputRadioGroup>
                </Grid>
                <Grid item xs={6}>
                  <Box
                    component={Grid}
                    display={displayBox(
                      transaction.data.Characteristics.WaterOnManhole !== 'Yes'
                    )}
                    item
                    xs={12}
                  >
                    <Grid item xs={12}>
                      <InputRadioGroup
                        name="Characteristics.WaterDisposalMethod"
                        value={
                          transaction.data.Characteristics
                            .WaterDisposalMethod || ''
                        }
                        label="Water Disposal Method"
                        onChange={onChange}
                      >
                        <InputRadio value="Not Pumped" label="Not Pumped" />
                        <InputRadio value="Tanker" label="Tanker" />
                        <InputRadio
                          value="Environmental"
                          label="Environmental (e.g. Creek)"
                        />
                        <InputRadio
                          value="Stormwater Drain"
                          label="Stormwater Drain"
                        />
                        <InputRadio
                          value="Adjacent Manhole"
                          label="Adjacent Manhole"
                        />
                      </InputRadioGroup>
                    </Grid>
                    <Grid item xs={12}>
                      <InputNumber
                        name="Characteristics.WaterVolumePumped"
                        value={
                          transaction.data.Characteristics.WaterVolumePumped
                        }
                        label="Water Volume Pumped"
                        onChange={onChange}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              Gallons
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Box>

            <Grid item xs={12}>
              <InputTextarea
                name="Characteristics.CharacteristicsComments"
                value={transaction.data.Characteristics.CharacteristicsComments}
                label="Characteristics Comments"
                onChange={onChange}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion classes={accordionClass} hidden={!ManholeAllowedToBeInspected}>
        <AccordionSummary classes={accordionSummaryClass}>
          Conditions
        </AccordionSummary>
        <AccordionDetails classes={accordionDetailsClass}>
          <Card classes={cardClass}>
            <CardHeader classes={cardHeaderClass} title="Oil Conditions" />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <InputRadioGroup
                    name="OilConditions.OilInManhole"
                    value={
                      transaction.data.OilConditions.OilInManhole || ''
                    }
                    label="Oil in Manhole"
                    onChange={onChange}
                  >
                    <InputRadio value="Yes" label="Yes" />
                    <InputRadio value="No" label="No" />
                  </InputRadioGroup>
                </Grid>
                <Box
                  component={Grid}
                  display={displayBox(
                    transaction.data.OilConditions.OilInManhole !== 'Yes'
                  )}
                  item
                  xs={6}
                >
                  <Grid container direction="column" spacing={2}>
                    <Grid item xs={12}>
                      <InputNumber
                        name="OilConditions.DepthOfOil"
                        value={transaction.data.OilConditions.DepthOfOil}
                        label="Depth of Oil"
                        onChange={onChange}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              Inches
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputRadioGroup
                        name="OilConditions.OilSheenVisible"
                        value={
                          transaction.data.OilConditions.OilSheenVisible || ''
                        }
                        label="Oil Sheen Visible"
                        onChange={onChange}
                      >
                        <InputRadio value="Yes" label="Yes" />
                        <InputRadio value="No" label="No" />
                      </InputRadioGroup>
                    </Grid>
                  </Grid>
                </Box>
                <Grid item xs={12}>
                  <InputTextarea
                    name="OilConditions.OilComments"
                    value={transaction.data.OilConditions.OilComments}
                    label="Oil Comments"
                    onChange={onChange}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card classes={cardClass}>
            <CardHeader classes={cardHeaderClass} title="Mud Conditions" />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <InputRadioGroup
                    name="MudConditions.IsThereMud"
                    value={
                      transaction.data.MudConditions.IsThereMud || ''
                    }
                    label="Mud"
                    onChange={onChange}
                  >
                    <InputRadio value="Yes" label="Yes" />
                    <InputRadio value="No" label="No" />
                  </InputRadioGroup>
                </Grid>
                <Box
                  component={Grid}
                  display={displayBox(
                    transaction.data.MudConditions.IsThereMud !== 'Yes'
                  )}
                  item
                  xs={6}
                >
                  <Grid container direction="column" spacing={2}>
                    <Grid item xs={12}>
                      <InputNumber
                        name="MudConditions.MudVolumeExtracted"
                        value={
                          transaction.data.MudConditions.MudVolumeExtracted
                        }
                        label="Mud Volume Extracted"
                        onChange={onChange}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">CFT</InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputRadioGroup
                        name="MudConditions.RemovedMudToExposeBottom"
                        value={
                          transaction.data.MudConditions
                            .RemovedMudToExposeBottom || ''
                        }
                        label="Removed Mud to Expose Bottom"
                        onChange={onChange}
                      >
                        <InputRadio value="Yes" label="Yes" />
                        <InputRadio value="No" label="No" />
                      </InputRadioGroup>
                    </Grid>
                  </Grid>
                </Box>
                <Grid item xs={12}>
                  <InputTextarea
                    name="MudConditions.MudComments"
                    value={transaction.data.MudConditions.MudComments}
                    label="Mud Comments"
                    onChange={onChange}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card classes={cardClass}>
            <CardHeader classes={cardHeaderClass} title="Sructure Conditions" />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <InputRadioGroup
                    name="StructureConditions.StructureCondition"
                    value={
                      transaction.data.StructureConditions.StructureCondition ||
                      ''
                    }
                    label="Is the Structure Condition Good?"
                    onChange={onChange}
                  >
                    <InputRadio value="Yes" label="Yes" />
                    <InputRadio value="No" label="No" />
                  </InputRadioGroup>
                </Grid>
                <Grid
                  item
                  xs={12}
                  hidden={
                    transaction.data.StructureConditions.StructureCondition !==
                    'No'
                  }
                >
                  <Grid container>
                    <Grid item xs={6}>
                      <InputRadioGroup
                        name="StructureConditions.ConcreteBad"
                        value={
                          transaction.data.StructureConditions.ConcreteBad || ''
                        }
                        label="Is There Bad Concrete?"
                        onChange={onChange}
                      >
                        <InputRadio value="Yes" label="Yes" />
                        <InputRadio value="No" label="No" />
                      </InputRadioGroup>
                    </Grid>
                    <Grid item xs={6}>
                      <InputCheckboxGroup
                        label="What has Bad Concrete?"
                        name="StructureConditions.ConcreteIssues"
                        hidden={
                          !(
                            transaction.data.StructureConditions.ConcreteBad ===
                            'Yes'
                          )
                        }
                        onChange={onChange}
                        value={
                          transaction.data.StructureConditions.ConcreteIssues ||
                          ''
                        }
                      >
                        <InputCheckbox
                          label="Chimney Crack"
                          value="Chimney Crack"
                        />
                        <InputCheckbox label="Wall Crack" value="Wall Crack" />
                        <InputCheckbox label="Roof Crack" value="Roof Crack" />
                      </InputCheckboxGroup>
                    </Grid>
                    <Grid item xs={6}>
                      <InputRadioGroup
                        name="StructureConditions.RebarBad"
                        value={
                          transaction.data.StructureConditions.RebarBad || ''
                        }
                        label="Is there Bad Rebar?"
                        onChange={onChange}
                      >
                        <InputRadio value="Yes" label="Yes" />
                        <InputRadio value="No" label="No" />
                      </InputRadioGroup>
                    </Grid>
                    <Grid item xs={6}>
                      <InputCheckboxGroup
                        label="What has Bad Rebar?"
                        name="StructureConditions.RebarIssues"
                        hidden={
                          !(
                            transaction.data.StructureConditions.RebarBad ===
                            'Yes'
                          )
                        }
                        onChange={onChange}
                        value={
                          transaction.data.StructureConditions.RebarIssues || ''
                        }
                      >
                        <InputCheckbox
                          label="Wall Rebar Exposed"
                          value="Wall Rebar Exposed"
                        />
                        <InputCheckbox
                          label="Roof Rebar Exposed"
                          value="Roof Rebar Exposed"
                        />
                      </InputCheckboxGroup>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <InputTextarea
                    name="StructureConditions.StructureComments"
                    value={
                      transaction.data.StructureConditions.StructureComments
                    }
                    label="Structure Comments"
                    onChange={onChange}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card classes={cardClass}>
            <CardHeader classes={cardHeaderClass} title="Cover Conditions" />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <InputRadioGroup
                    name="CoverConditions.CoverCondition"
                    value={
                      transaction.data.CoverConditions.CoverCondition || ''
                    }
                    label="Is the Cover Condition Good?"
                    onChange={onChange}
                  >
                    <InputRadio value="Yes" label="Yes" />
                    <InputRadio value="No" label="No" />
                  </InputRadioGroup>
                </Grid>
                <Grid item xs={6}>
                  <InputRadioGroup
                    name="CoverConditions.ManholeLid"
                    value={
                      transaction.data.CoverConditions.ManholeLid || ''
                    }
                    label="ManholeLid"
                    onChange={onChange}
                  >
                    <InputRadio value="Good" label="Good" />
                    <InputRadio value="Crack" label="Crack" />
                    <InputRadio
                      value="Broken in Half/Missing"
                      label="Broken in Half/Missing"
                    />
                  </InputRadioGroup>
                </Grid>
                <Grid item xs={12}>
                  <InputRadioGroup
                    name="CoverConditions.ManholeGrade"
                    value={
                      transaction.data.CoverConditions.ManholeGrade || ''
                    }
                    label="Manhole Grade"
                    onChange={onChange}
                    hidden={
                      !(
                        transaction.data.CoverConditions.CoverCondition === 'No'
                      )
                    }
                  >
                    <InputRadio value="To Grade" label="To Grade" />
                    <InputRadio value="Not To Grade" label="Not To Grade" />
                    <InputRadio
                      value="Public Tripping Hazard"
                      label="Public Tripping Hazard"
                    />
                  </InputRadioGroup>
                </Grid>

                <Grid item xs={12}>
                  <InputTextarea
                    name="CoverConditions.CoverComments"
                    value={transaction.data.CoverConditions.CoverComments}
                    label="Cover Comments"
                    onChange={onChange}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

TransmissionHoleGeneral.propTypes = {
  transaction: PropTypes.objectOf(PropTypes.any),
  onChange: PropTypes.func,
};
TransmissionHoleGeneral.defaultProps = {
  transaction: {},
  onChange: () => { },
};

export default TransmissionHoleGeneral;
