/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { toast } from 'react-toastify';

import Wizard from './Wizard';
import TransmissionHoleGeneral from './TransmissionHoleGeneral';
import TransmissionHoleLinkBox from './TransmissionHoleLinkBox';
import TransmissionHoleCableSupport from './TransmissionHoleCableSupport';
import TransmissionPhoto from './TransmissionPhoto';
import SummaryReport from './SummaryReport';

import Gauges from './Gauges';
import Reservoirs from './Reservoirs';
import DuctBanks from './DuctBanks';
import Cables from './Cables';
import Joints from './Joints';
import TransmissionPDF from './TransmissionPdf';

import { firestore } from '../firebase';

import { UserContext } from '../providers/UserProvider';

const CableWizard = (props) => {
  const { match, history } = props;
  const user = useContext(UserContext);
  const [transmissionInspection, setTransmissionInspection] = useState({
    path: `Manholes/${match.params.id}`,
    id: 'manhole-id',
    collection: 'Manholes',
    CreatedBy: '',
    CreatedDate: '',
    ModifiedBy: '',
    ModifiedDate: '',
    data: {
      General: {
        GridNumber: '',
        LegacyGridNumber: '',
        InspectionDate: '',
        CrewLead: '',
        Address: '',
        AccessManhole: '',
        AccessIssues: '',
        GeneralComments: '',
      },
      Characteristics: {
        InScope: '',
        IsClean: '',
        ManholeLength: 0,
        ManholeWidth: 0,
        DepthClearance: 0,
        CeilingToSurface: 0,
        WaterExists: '',
        WaterDisposalMethod: '',
        WaterVolumePumped: 0,
        CharacteristicsComments: '',
      },
      OilConditions: {
        OilExists: '',
        DepthOfOil: 0,
        OilSheenVisible: '',
        OilComments: '',
      },
      MudConditions: {
        MudExists: '',
        MudVolumeExtracted: 0,
        MudRemovedToExposeBottom: '',
        MudComments: '',
      },
      StructureConditions: {
        StructureCondition: '',
        ConcreteBad: '',
        ConcreteIssues: '',
        RebarBad: '',
        RebarIssues: '',
        StructureComments: '',
      },
      CoverConditions: {
        CoverCondition: '',
        ManholeGrade: '',
        ManholeLid: '',
        CoverComments: '',
      },
      PlumbingConditions: {
        PlumbingPresent: '',
        PlumbingCondition: '',
        PipeCondition: '',
        ValveCondition: '',
        PlumbingComments: '',
      },
      LinkBoxConditions: {
        LinkBoxPresent: '',
        LinkBoxID: '',
        LinkBoxDisconnected: '',
        LinkBoxComments: '',
      },
      CableSupportConditions: {
        MudOnCableSupports: '',
        CleaningBeforeCM: '',
        AlarmCablesHeldByZipTies: '',
        SupportSystemBad: '',
        TeakBlocksBad: '',
        NumberOfBadTeakBlocks: 0,
        TurnbucklePresent: '',
        NumberOfBadTurnbuckles: 0,
        UprightsBad: '',
        NumberOfBadUprights: 0,
        ArmsBad: '',
        NumberOfBadArms: 0,
        SaddlesBad: '',
        NumberOfBadSaddles: 0,
        RackingRequired: '',
        CableSupportComments: '',
      },
      GroundingConditions: {
        GroundSystemBad: '',
        Hangers5ftBad: '',
        GroundRodBad: '',
        GroundLoopBad: '',
        GroundingComments: '',
      },
      Priorities: {
        General: {
          P10: 0,
          P10Display: '',
          P20: 0,
          P20Display: '',
          P30: 0,
          P30Display: '',
          P40: 0,
          P40Display: '',
        },
        Structural: {
          P10: 0,
          P10Display: '',
          P20: 0,
          P20Display: '',
          P30: 0,
          P30Display: '',
          P40: 0,
          P40Display: '',
        },
        CableSupport: {
          P10: 0,
          P10Display: '',
          P20: 0,
          P20Display: '',
          P30: 0,
          P30Display: '',
          P40: 0,
          P40Display: '',
        },
        Grounding: {
          P10: 0,
          P10Display: '',
          P20: 0,
          P20Display: '',
          P30: 0,
          P30Display: '',
          P40: 0,
          P40Display: '',
        },
        Cable: {
          P10: 0,
          P10Display: '',
          P20: 0,
          P20Display: '',
          P30: 0,
          P30Display: '',
          P40: 0,
          P40Display: '',
        },
        Environmental: {
          P10: 0,
          P10Display: '',
          P20: 0,
          P20Display: '',
          P30: 0,
          P30Display: '',
          P40: 0,
          P40Display: '',
        },
        Equipment: {
          P10: 0,
          P10Display: '',
          P20: 0,
          P20Display: '',
          P30: 0,
          P30Display: '',
          P40: 0,
          P40Display: '',
        },
      },
    },
  });

  const [showButtons, setShowButtons] = useState(true);

  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const submitToastId = useRef(null);

  useEffect(async () => {
    const RECORDID = await (async () => {
      if (match.params.id === 'new' || typeof match.params.id === 'undefined') {
        const newId = (await firestore.collection('Manholes').doc()).id;
        return newId;
      }
      return match.params.id;
    })();
    try {
      setLoading(true);
      firestore
        .collection('Manholes')
        .doc(RECORDID)
        .onSnapshot(
          (doc) => {
            if (doc.exists) {
              const record = doc.data();
              setTransmissionInspection(record);
            } else {
              const record = {
                ...transmissionInspection,
                id: RECORDID,
                path: `Manholes/${RECORDID}`,
              };
              setSaving(true);
              firestore
                .collection('Manholes')
                .doc(RECORDID)
                .set(record, { merge: true })
                .then((res) => {
                  setSaving(false);
                })
                .catch((err) => {
                  console.log(err);
                  setSaving(false);
                });
              history.push(`/transmission/${RECORDID}`);
              setTransmissionInspection(record);
            }
            setLoading(false);
          },
          (err) => {
            toast.error(err.message);
          }
        );
    } catch (err) {
      toast.error(err.message);
    }
  }, []);

  const saveOnChange = () => {
    setSaving(true);
    firestore
      .collection('Manholes')
      .doc(transmissionInspection.id)
      .set(transmissionInspection, { merge: true })
      .then((res) => {
        setSaving(false);
      })
      .catch((err) => {
        console.log(err);
        setSaving(false);
      });
  };
  const onChange = (event, name, displayFromTypeahead) => {
    try {
      const id =
        (typeof name !== 'undefined' ? name.split('.') : name) ||
        event.target.name.split('.');
      const value = typeof name !== 'undefined' ? event : event.target.value;
      const changedTransmissionInspection = { ...transmissionInspection };
      changedTransmissionInspection.data[id[0]][id[1]] = value;

      if (id[1] === 'InspectionDate') {
        const [date, time] = value.split('T');
        changedTransmissionInspection.data.General.InspectionDate_Date = date;
        changedTransmissionInspection.data.General.InspectionDate_Time = time;
      }
      if (typeof displayFromTypeahead !== 'undefined') {
        changedTransmissionInspection.data[id[0]][`${id[1]}Display`] =
          displayFromTypeahead;
      }
      if (changedTransmissionInspection.CreatedBy === '') {
        changedTransmissionInspection.CreatedBy = user.email;
        changedTransmissionInspection.CreatedDate = new Date().toJSON();
      }

      changedTransmissionInspection.ModifiedBy = user.email;
      changedTransmissionInspection.ModifiedDate = new Date().toJSON();
      setTransmissionInspection(changedTransmissionInspection);
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    }
  };

  useEffect(() => {
    if (transmissionInspection.data.General.CrewLead === '') {
      const changedTransmissionInspection = { ...transmissionInspection };
      changedTransmissionInspection.data.General.CrewLead = user.name;
      setTransmissionInspection(changedTransmissionInspection);
    }
  }, [transmissionInspection]);

  const handleSave = () => {
    setSaving(true);
    try {
      if (transmissionInspection.id === '') {
        const docRef = firestore.collection('Manholes').doc();
        transmissionInspection.id = docRef.id;
        docRef
          .set(transmissionInspection, { merge: true })
          .then(() => {
            setSaving(false);
          })
          .catch((err) => {
            setSaving(false);
            toast.error(err);
          });
      } else {
        const docRef = firestore
          .collection('Manholes')
          .doc(transmissionInspection.id);
        docRef
          .set(transmissionInspection, { merge: true })
          .then(() => {
            setSaving(false);
          })
          .catch((err) => {
            setSaving(false);
            toast.error(err);
          });
      }
    } catch (err) {
      setSaving(false);
      toast.error(err.toString());
    }
  };
  const onBlur = () => { };
  useEffect(() => {
    if (!navigator.onLine && saving) {
      setSaving(false);
    }
    if (saving && navigator.onLine) {
      submitToastId.current = toast('Submitting Record', {
        position: 'top-center',
        autoClose: false,
      });
    }
    if (!saving && submitToastId.current !== null) {
      toast.dismiss(submitToastId.current);
    }
  }, [saving, navigator.onLine]);

  const AccessToManhole =
    transmissionInspection.data.General.AccessManhole === 'Yes';
  const ManholeAllowedToBeInspected =
    AccessToManhole &&
    transmissionInspection.data.Characteristics.InScope === 'Yes' &&
    transmissionInspection.data.Characteristics.IsClean === 'Yes';
  const isAdmin = user.admin;
  const steps = [
    {
      name: 'General',
      disabled: !showButtons,
      component: <TransmissionHoleGeneral />,
    },
    {
      name: 'Gauges',
      disabled: !showButtons,
      component: <Gauges />,
      isVisible: ManholeAllowedToBeInspected,
    },
    {
      name: 'Reservoirs',
      disabled: !showButtons,
      component: <Reservoirs />,
      isVisible: ManholeAllowedToBeInspected,
    },
    {
      name: 'Plumbing & Link Box',
      disabled: !showButtons,
      component: <TransmissionHoleLinkBox />,
      isVisible: ManholeAllowedToBeInspected,
    },
    {
      name: 'Cables',
      disabled: !showButtons,
      component: <Cables />,
      isVisible: ManholeAllowedToBeInspected,
    },
    {
      name: 'Joints',
      disabled: !showButtons,
      component: <Joints />,
      isVisible: ManholeAllowedToBeInspected,
    },
    {
      name: 'Ducts',
      disabled: !showButtons,
      component: <DuctBanks />,
      isVisible: ManholeAllowedToBeInspected,
    },
    {
      name: 'Cable Support',
      disabled: !showButtons,
      component: <TransmissionHoleCableSupport />,
      isVisible: ManholeAllowedToBeInspected,
    },
    { name: 'Photos', component: <TransmissionPhoto /> },
    {
      name: 'Summary Report',
      component: <SummaryReport />,
      isVisible: isAdmin,
    },
    {
      name: 'PDF Report',
      component: <TransmissionPDF />,
      isVisible: isAdmin,
    },
  ];
  return (
    <>
      {saving ? (
        <div
          style={{
            display: 'block',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: '#efefef',
            opacity: 0.5,
            zIndex: 1200,
          }}
        />
      ) : (
        <></>
      )}
      <div
        style={{
          position: 'sticky',
          top: 0,
          left: 0,
          right: 0,
          width: '100%',
          backgroundColor: 'white',
          padding: '5px',
          display: 'flex',
          justifyContent: 'flex-end',
          zIndex: 100,
          minHeight: '46px',
        }}
      >
        {showButtons ? (
          <>
            <Button
              variant="contained"
              color="primary"
              style={{ marginRight: '5px' }}
              onClick={handleSave}
            >
              Save
            </Button>
            <Link to="/">
              <Button variant="contained">Close</Button>
            </Link>
          </>
        ) : null}
      </div>
      <Wizard
        steps={steps}
        transaction={transmissionInspection}
        setTransaction={setTransmissionInspection}
        onChange={onChange}
        onBlur={onBlur}
        saveOnChange={saveOnChange}
        setShowButtons={setShowButtons}
      />
    </>
  );
};

export default CableWizard;
