import PropTypes from 'prop-types';
import React, { useContext, useState, useEffect } from 'react';
// import { useRecoilState } from 'recoil';
import dayjs from 'dayjs';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import Loader from 'react-loader-spinner';
import { Delete, CameraAlt } from '@material-ui/icons';
import { ModalBox, useModalState } from '@aldridge/aldg-confirm';
import { toast } from 'react-toastify';
import { firestore, storage } from '../firebase';
import { UserContext } from '../providers/UserProvider';
// import { _CurrentGridNumber, _CurrentLegacyGridNumber } from '../_Recoil/atoms';

const TransmissionTable = (props) => {
  const { Transmissions, onSelect } = props;
  const user = useContext(UserContext);
  // const [CurrentGridNumber] = useRecoilState(_CurrentGridNumber);
  // const [CurrentLegacyGridNumber] = useRecoilState(_CurrentLegacyGridNumber);

  const [modal, setModalOpen, setModalProps] = useModalState({
    open: false,
    title: 'Delete Transmission Hole?',
    message: 'Do you want to delete this record and all its data?',
    buttons: [
      {
        text: 'Delete',
        color: 'primary',
        onClick: async (record, { close }) => {
          try {
            await firestore.collection('Manholes').doc(record.id).delete();

            toast.success('Transmission Hole Deleted.');
          } catch (err) {
            toast.error(err.toString());
          }
          close();
        },
      },
      {
        text: 'Close',
        onClick: (record, { close }) => {
          close();
        },
      },
    ],
    onBackdropClick: ({ close }) => {
      close();
    },
  });
  const handleModal = () => {
    setModalOpen(true);
  };
  const handleDeleteClick = (row) => {
    setModalProps({ record: row });
    handleModal(row);
  };

  const [createLoading, setCreateLoading] = useState(false);
  const [createError, setCreateError] = useState(undefined);
  useEffect(() => {
    if (typeof createError === 'undefined') {
      toast.error(createError);
    }
  }, [createError]);
  const uploadFiles = (transaction) => {
    if (
      typeof transaction.data.General.GridNumber === 'undefined' ||
      transaction.data.General.GridNumber === ''
    ) {
      toast.error('Grid Number cannot be blank to upload photos.');
      return;
    }
    if (
      typeof transaction.data.General.CrewLead === 'undefined' ||
      transaction.data.General.CrewLead === ''
    ) {
      toast.error('Crew Lead cannot be blank to upload photos.');
      return;
    }
    if (
      typeof transaction.data.General.InspectionDate_Date === 'undefined' ||
      transaction.data.General.InspectionDate_Date === ''
    ) {
      toast.error('Inspection Date cannot be blank to upload photos.');
      return;
    }
    const folder = `${transaction.data.General.GridNumber}/${transaction.data.General.CrewLead}/${transaction.data.General.InspectionDate_Date}`;
    const fileUpBtn = document.createElement('input');
    fileUpBtn.type = 'file';
    fileUpBtn.multiple = true;
    fileUpBtn.name = 'fileUpload';
    fileUpBtn.id = 'fileUpload';
    fileUpBtn.style = 'display:none;';
    const upFile = (fileToUpload) =>
      new Promise((resolve, reject) => {
        const ref = storage.ref();
        let metadata = {};
        if (fileToUpload.type.indexOf('image/') > -1) {
          const fr = new FileReader();
          fr.readAsDataURL(fileToUpload);
          fr.onload = () => {
            const img = new Image();
            img.src = fr.result;
            img.onload = () => {
              metadata = {
                customMetadata: {
                  width: img.width,
                  height: img.height,
                },
              };
              const f = ref.child(
                `${folder}/${fileToUpload.name
                  .replace(/\//g, '_')
                  .replace(/ /g, '_')}`
              );
              const task = f.put(fileToUpload, metadata);
              task.then(() => {
                toast.success('File Uploaded Successfully.', {
                  autoClose: 2000,
                });
                resolve();
              });
              task.catch((err) => {
                toast.success(`File ${fileToUpload.name} Failed To Upload.`, {
                  autoClose: 8000,
                });
                setCreateError(err.message);
                reject();
              });
            };
          };
        } else {
          const f = ref.child(
            `${folder}/${fileToUpload.name
              .replace(/\//g, '_')
              .replace(/ /g, '_')}`
          );
          const task = f.put(fileToUpload);
          task.then(() => {
            toast.success('File Uploaded Successfully.', { autoClose: 2000 });
            resolve();
          });
          task.catch((err) => {
            toast.success(`File ${fileToUpload.name} Failed To Upload.`, {
              autoClose: 8000,
            });
            setCreateError(err.message);
            reject();
          });
        }
      });
    const p = [];
    fileUpBtn.addEventListener('change', (e) => {
      setCreateLoading(true);
      setCreateError(undefined);
      for (let i = 0; i < e.target.files.length; i++) {
        p.push(upFile(e.target.files[i]));
      }
      Promise.all(p).then(() => {
        document.body.removeChild(fileUpBtn);
        setCreateLoading(false);
      });
    });
    document.body.appendChild(fileUpBtn);
    fileUpBtn.click();
  };
  return (
    <>
      <ModalBox modal={modal} />
      {createLoading ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            flexDirection: 'column',
          }}
        >
          <Loader type="TailSpin" />
          <div>Uploading Files...</div>
        </div>
      ) : null}
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Grid Number</TableCell>
              <TableCell>Legacy Grid Number</TableCell>
              <TableCell>Inspection Date</TableCell>
              <TableCell>Inspectors</TableCell>
              <TableCell>Location</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {Transmissions.map((row) => (
              <TableRow
                hover
                key={row.id}
                style={{ cursor: 'pointer' }}
              >
                <TableCell onClick={() => onSelect(row.id)}>{row.data.General.GridNumber}</TableCell>
                <TableCell onClick={() => onSelect(row.id)}>{row.data.General.LegacyGridNumber}</TableCell>
                <TableCell onClick={() => onSelect(row.id)}>
                  {row.data.General.InspectionDate !== ''
                    ? dayjs(row.data.General.InspectionDate).format(
                      'MMM DD YYYY, hh:ss A'
                    )
                    : null}
                </TableCell>
                <TableCell onClick={() => onSelect(row.id)}>{row.data.General.CrewLead}</TableCell>
                <TableCell onClick={() => onSelect(row.id)}>
                  {row.data.General.Location?.formattedAddress}
                </TableCell>
                <TableCell>
                  {user.admin ? (
                    <>
                      <Delete
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDeleteClick(row);
                        }}
                      />
                      <CameraAlt
                        onClick={(e) => {
                          e.stopPropagation();
                          uploadFiles(row);
                        }}
                      />
                    </>
                  ) : null}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

TransmissionTable.propTypes = {
  Transmissions: PropTypes.arrayOf(PropTypes.any),
  queryOptions: PropTypes.shape({
    limit: PropTypes.number,
    offset: PropTypes.number,
    sort: PropTypes.string,
    direction: PropTypes.string,
  }),
  onSelect: PropTypes.func,
};
TransmissionTable.defaultProps = {
  Transmissions: [],
  queryOptions: {
    limit: 100,
    offset: 0,
    sort: null,
    direction: null,
  },
  onSelect: () => { },
};

export default TransmissionTable;
