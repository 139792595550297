/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  InputAdornment,
  Grid,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Button,
  TableBody,
} from '@material-ui/core';
import { toast } from 'react-toastify';
import { Delete } from '@material-ui/icons';

import {
  InputDate,
  InputText,
  InputRadio,
  InputRadioGroup,
  InputNumber,
  InputTextarea,
  InputCheckbox,
  InputCheckboxGroup,
  InputAutocomplete,
} from '@aldridge/aldg-data-components';
import { ModalBox, useModalState } from '@aldridge/aldg-confirm';
import { firestore } from '../firebase';
import { UserContext } from '../providers/UserProvider';

const JointsList = (props) => {
  const { joints, onSelect, removeRecord } = props;

  return (
    <>
      <Grid container spacing={3}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Joint Type</TableCell>
                <TableCell>Cables</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {joints.map((row) => (
                <TableRow onClick={() => onSelect(row.id)} key={row.id}>
                  <TableCell>{row.data.JointType}</TableCell>
                  <TableCell>{row.data.CablesDisplay}</TableCell>
                  <TableCell align="right">
                    <Delete
                      onClick={(e) => {
                        e.stopPropagation();
                        removeRecord(row);
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </>
  );
};

JointsList.propTypes = {
  joints: PropTypes.arrayOf(PropTypes.any),
  onSelect: PropTypes.func,
  removeRecord: PropTypes.func.isRequired,
};
JointsList.defaultProps = {
  joints: [],
  onSelect: () => { },
};

const JointsForm = (props) => {
  const { transaction, setTransaction, index, closeRecord } = props;
  const user = useContext(UserContext);
  const [record, setRecord] = useState({
    id: '',
    ManholeID: transaction.id,
    path: '',
    collection: 'Joints',
    CreatedBy: '',
    CreatedDate: '',
    ModifiedBy: '',
    ModifiedDate: '',
    data: {
      JointType: '',
      JointConditionBad: '',
      JointIssues: '',
      Cables: [],
      TapeDisbondmentCondition: '',
      JointComments: '',
    },
  });

  useEffect(() => {
    let mounted = true;
    if (index !== '') {
      firestore
        .collection(`Manholes/${transaction.id}/Joints`)
        .doc(index)
        .onSnapshot((doc) => {
          if (doc.exists && mounted) {
            setRecord(doc.data());
          } else {
            setRecord({
              ...record,
              id: doc.id,
              path: `Manholes/${transaction.id}/Joints/${doc.id}`,
            });
          }
        });
    } else {
      const docRef = firestore
        .collection(`Manholes/${transaction.id}/Joints`)
        .doc();
      if (mounted)
        setRecord({
          ...record,
          id: docRef.id,
          path: `Manholes/${transaction.id}/Joints/${docRef.id}`,
        });
    }
    return () => (mounted = false);
  }, [index]);

  const onChange = (event, name, displayFromTypeahead) => {
    try {
      const id = name || event.target.name;
      const value = typeof name !== 'undefined' ? event : event.target.value;
      const changedRecord = { ...record };
      changedRecord.data[id] = value;
      if (typeof displayFromTypeahead !== 'undefined') {
        changedRecord.data[`${id}Display`] = displayFromTypeahead;
      }
      if (changedRecord.CreatedDate === '') {
        changedRecord.CreatedBy = ''; // user.email;
        changedRecord.CreatedDate = new Date().toJSON();
      }
      changedRecord.ModifiedBy = ''; // user.email;
      changedRecord.ModifiedDate = new Date().toJSON();
      setRecord(changedRecord);
    } catch (err) {
      toast.error(err.message);
    }
  };

  const addJoints = () => {
    firestore
      .collection(`Manholes/${record.ManholeID}/Joints`)
      .doc(`${record.id}`)
      .set(record, { merge: true })
      .then((res) => {
        closeRecord();
      });
  };
  return (
    <>
      <div className="subcollectionButtons">
        <Button
          variant="contained"
          color="primary"
          style={{ marginRight: '5px' }}
          onClick={addJoints}
        >
          {index === '' ? 'Add' : 'Update'} Joint
        </Button>
        <Button variant="contained" onClick={closeRecord}>
          Close
        </Button>
      </div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <InputRadioGroup
            name="JointType"
            value={record.data.JointType || ''}
            label="Joint Type"
            onChange={onChange}
          >
            <InputRadio value="Straight" label="Straight" />
            <InputRadio value="Stop" label="Stop" />
          </InputRadioGroup>
        </Grid>
        <Grid item xs={6}>
          <InputRadioGroup
            name="JointConditionBad"
            value={record.data.JointConditionBad || ''}
            label="Is the Joint Condition Bad?"
            onChange={onChange}
          >
            <InputRadio value="Yes" label="Yes" />
            <InputRadio value="No" label="No" />
          </InputRadioGroup>
        </Grid>
        <Grid item xs={6}>
          <InputCheckboxGroup
            label="What has Bad Conditions?"
            name="JointIssues"
            hidden={!(record.data.JointConditionBad === 'Yes')}
            onChange={onChange}
            value={record.data.JointIssues}
          >
            <InputCheckbox label="Leak" value="Leak" />
            <InputCheckbox label="Deformed" value="Deformed" />
            <InputCheckbox label="Mud" value="Mud" />
            <InputCheckbox
              label="#2 Ground Disconnected"
              value="#2 Ground Disconnected"
            />
          </InputCheckboxGroup>
        </Grid>
        <Grid item xs={12}>
          <InputRadioGroup
            name="TapeDisbondmentCondition"
            value={record.data.TapeDisbondmentCondition || ''}
            label="Tape Disbondment Condition"
            onChange={onChange}
          >
            <InputRadio value="Not Present" label="Not Present" />
            <InputRadio value="Needs Repair" label="Needs Repair" />
          </InputRadioGroup>
        </Grid>
        <Grid item xs={12}>
          <InputAutocomplete
            name="Cables"
            label="Cables Attached to Joint"
            onChange={onChange}
            value={record.data.Cables || []}
            optionDisplay={['option_CableID', ' - ', 'option_Contents']}
            valueKey="id"
            collection={`Manholes/${record.ManholeID}/Cables`}
            required
            multiple
            enterprise={false}
            firestore={firestore}
          />
        </Grid>

        <Grid item xs={12}>
          <InputTextarea
            name="JointComments"
            value={record.data.JointComments}
            label="Joint Comments"
            onChange={onChange}
          />
        </Grid>
      </Grid>
    </>
  );
};

JointsForm.propTypes = {
  transaction: PropTypes.objectOf(PropTypes.any),
  setTransaction: PropTypes.func.isRequired,
  index: PropTypes.string.isRequired,
  closeRecord: PropTypes.func.isRequired,
};
JointsForm.defaultProps = {
  transaction: {},
};

const Joints = (props) => {
  const { transaction, setTransaction, setShowButtons } = props;
  const [view, setView] = useState('table');
  const [recordId, setRecordId] = useState('');
  const [joints, setJoints] = useState([]);

  const handleSelect = (rid) => {
    setRecordId(rid);
    setView('form');
    setShowButtons(false);
  };
  const handleNew = () => {
    setView('form');
    setShowButtons(false);
  };
  const closeRecord = () => {
    setView('table');
    setRecordId('');
    setShowButtons(true);
  };
  useEffect(() => {
    firestore
      .collection(`Manholes/${transaction.id}/Joints`)
      .onSnapshot((res) => {
        const allJoints = [];
        res.forEach((doc) => allJoints.push(doc.data()));
        setJoints(allJoints);
      });
  }, [transaction]);

  const removeRecord = async (r) => {
    await firestore
      .collection(`Manholes/${r.ManholeID}/Joints`)
      .doc(r.id)
      .delete();

    toast.success('Gauge Deleted.');
  };
  const [modal, setModalOpen, setModalProps] = useModalState({
    open: false,
    title: 'Delete Joint',
    message: 'Would you like to delete this Joint?',
    record: {},
    buttons: [
      {
        text: 'Delete',
        onClick: (r, { close }) => {
          removeRecord(r);
          close();
        },
      },
      {
        text: 'Cancel',
        onClick: (r, { close }) => {
          close();
        },
      },
    ],
    onBackdropClick: ({ close }) => {
      close();
    },
  });
  const handleDelete = (record) => {
    setModalProps({ record });
    setModalOpen(true);
  };
  const selectView = () => {
    if (view === 'table') {
      return (
        <>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
              marginBottom: '16px',
            }}
          >
            <Button variant="contained" color="primary" onClick={handleNew}>
              Add Joint
            </Button>
          </div>
          <JointsList
            joints={joints}
            onSelect={handleSelect}
            removeRecord={handleDelete}
          />
        </>
      );
    }
    return (
      <JointsForm
        index={recordId}
        transaction={transaction}
        setTransaction={setTransaction}
        closeRecord={closeRecord}
      />
    );
  };
  return (
    <>
      <ModalBox modal={modal} />

      {selectView()}
    </>
  );
};

Joints.propTypes = {
  transaction: PropTypes.objectOf(PropTypes.any),
  setTransaction: PropTypes.func,
  setShowButtons: PropTypes.func,
};
Joints.defaultProps = {
  transaction: {},
  setTransaction: () => { },
  setShowButtons: () => { },
};

export default Joints;
