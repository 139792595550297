import { RecoilRoot } from 'recoil';

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import Loader from 'react-loader-spinner';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import './_assets/base.scss';

import Header from './_GlobalComponents/Header';
import Footer from './_GlobalComponents/Footer';
import Application from './Pages/Application';
import UserProvider from './providers/UserProvider';
// import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import ServiceWorkerWrapper from './ServiceWorkerWrapper';

require('dotenv').config();

const rootElement = document.getElementById('root');

const renderApp = () => {
  ReactDOM.render(

    <Suspense
      fallback={
        <div className="loader-container">
          <div className="loader-container-inner">
            <div className="text-center">
              <Loader type="TailSpin" />
            </div>
            <h6 className="mt-3">
              Please wait while we load the Transmission Application
            </h6>
          </div>
        </div>
      }
    >
      <UserProvider>
        <BrowserRouter>
          <RecoilRoot>
            <ToastContainer
              position="bottom-left"
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
            <div
              id="appParent"
              style={{
                display: 'flex',
                height: '100%',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
            >
              <Header appTitle="Transmission" />
              <Application />
              <Footer
                version="1.4.9"
                copy={`${new Date().getFullYear()} Aldridge, All Rights Reserved`}
              />
              <ServiceWorkerWrapper />
            </div>
          </RecoilRoot>
        </BrowserRouter>
      </UserProvider>
    </Suspense>,
    rootElement
  );
};
renderApp();
// serviceWorkerRegistration.register();
