/* eslint-disable arrow-body-style */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import {
  InputAdornment,
  Grid,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  Button,
  withStyles,
  makeStyles,
  TableBody,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useFunctionCall } from '@aldridge/aldg-helpers';
import { useModalState } from '@aldridge/aldg-confirm';
import { storage, auth, functions, config } from '../firebase';

const SummaryReport = (props) => {
  const { transaction = '' } = props;

  function createData(
    name,
    { P10, P10Display, P20, P20Display, P30, P30Display, P40, P40Display }
  ) {
    return (
      <TableRow key={name}>
        <TableCell component="th" scope="row" style={{ fontWeight: 'bold' }}>
          {name}
        </TableCell>
        <TableCell align="right">{P10}</TableCell>
        <TableCell align="left">
          {P10Display.split(`, `).map((res) => {
            return <div key={res.replace(/ /g, '')}>{res}</div>;
          })}
        </TableCell>
        <TableCell align="right">{P20}</TableCell>
        <TableCell align="left">
          {P20Display.split(`, `).map((res) => {
            return <div key={res.replace(/ /g, '')}>{res}</div>;
          })}
        </TableCell>
        <TableCell align="right">{P30}</TableCell>
        <TableCell align="left">
          {P30Display.split(`, `).map((res) => {
            return <div key={res.replace(/ /g, '')}>{res}</div>;
          })}
        </TableCell>
        <TableCell align="right">{P40}</TableCell>
        <TableCell align="left">
          {P40Display.split(`, `).map((res) => {
            return <div key={res.replace(/ /g, '')}>{res}</div>;
          })}
        </TableCell>
      </TableRow>
    );
  }
  const rows = [
    createData('Cable', transaction.data.Priorities?.Cable),
    createData('Cable Support', transaction.data.Priorities?.CableSupport),
    createData('Environmental', transaction.data.Priorities?.Environmental),
    createData('Equipment', transaction.data.Priorities?.Equipment),
    createData('General', transaction.data.Priorities?.General),
    createData('Grounding', transaction.data.Priorities?.Grounding),
    createData('Structural', transaction.data.Priorities?.Structural),
  ];
  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);

  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

  const useStyles = makeStyles({
    table: {
      minWidth: 700,
    },
  });

  return (
    <TableContainer component={Paper}>
      <Table aria-label="customized table">
        <TableHead style={{ backgroundColor: '#B2B2B2' }}>
          <TableRow>
            <StyledTableCell>Summary Info</StyledTableCell>
            <StyledTableCell
              align="center"
              style={{ overflowWrap: 'break-word' }}
            >
              P10
            </StyledTableCell>
            <StyledTableCell align="center">P10 Description</StyledTableCell>
            <StyledTableCell
              align="center"
              style={{ overflowWrap: 'break-word' }}
            >
              P20
            </StyledTableCell>
            <StyledTableCell align="center">P20 Description</StyledTableCell>
            <StyledTableCell
              align="center"
              style={{ overflowWrap: 'break-word' }}
            >
              P30
            </StyledTableCell>
            <StyledTableCell align="center">P30 Description</StyledTableCell>
            <StyledTableCell
              align="center"
              style={{ overflowWrap: 'break-word' }}
            >
              P40
            </StyledTableCell>
            <StyledTableCell align="center">P40 Description</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>{rows.map((row) => row)}</TableBody>
      </Table>
    </TableContainer>
  );
};

//

SummaryReport.propTypes = {
  transaction: PropTypes.objectOf(PropTypes.any),
};
SummaryReport.defaultProps = {
  transaction: {},
};

export default SummaryReport;
