/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  InputAdornment, Grid, Table, TableContainer, TableHead, TableRow, TableCell, Button, TableBody, Box
} from '@material-ui/core';
import { toast } from 'react-toastify';
import { Delete } from '@material-ui/icons';

import {
  InputDate, InputText, InputRadio, InputRadioGroup, InputNumber, InputTextarea, InputAutocomplete
} from '@aldridge/aldg-data-components';
import { ModalBox, useModalState } from '@aldridge/aldg-confirm';
import { firestore } from '../firebase';
import { UserContext } from '../providers/UserProvider';

const ReservoirsList = (props) => {
  const { reservoirs, onSelect, removeRecord } = props;

  return (
    <>
      <Grid container spacing={3}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Reservoir ID</TableCell>
                <TableCell>{' '}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {reservoirs.map((row, idx) => (
                <TableRow style={{ cursor: 'pointer' }} hover onClick={() => onSelect(row.id)} key={row.id}>
                  <TableCell>{row.data.ReservoirID}</TableCell>
                  <TableCell align="right"><Delete onClick={(e) => { e.stopPropagation(); removeRecord(row); }} /></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </>
  );
};

ReservoirsList.propTypes = {
  reservoirs: PropTypes.arrayOf(PropTypes.any),
  onSelect: PropTypes.func,
  removeRecord: PropTypes.func.isRequired
};
ReservoirsList.defaultProps = {
  reservoirs: [],
  onSelect: () => { }
};

const ReservoirsForm = (props) => {
  const {
    transaction, setTransaction, index, closeRecord
  } = props;
  const user = useContext(UserContext);
  const [record, setRecord] = useState({
    id: '',
    ManholeID: transaction.id,
    path: `Manholes/${transaction.id}/Reservoirs/${index}`,
    collection: 'Reservoirs',
    CreatedBy: '',
    CreatedDate: '',
    ModifiedBy: '',
    ModifiedDate: '',
    data: {
      ReservoirID: '',
      ReservoirConditionBad: '',
      ReservoirRust: '',
      ReservoirLeaking: '',
      ReservoirGroundPoor: '',
      ReservoirGauges: [],
      ReservoirType: '',
      ReservoirComments: ''
    }
  });

  useEffect(() => {
    let mounted = true;
    if (index === '') {
      const docRef = firestore
        .collection(`Manholes/${record.ManholeID}/Reservoirs`)
        .doc();
      if (mounted) setRecord({ ...record, id: docRef.id, path: `Manholes/${transaction.id}/Reservoirs/${docRef.id}` });
    } else {
      firestore
        .collection(`Manholes/${record.ManholeID}/Reservoirs`)
        .doc(index)
        .onSnapshot((doc) => {
          if (mounted) {
            if (doc.exists) {
              setRecord(doc.data());
            } else {
              setRecord({ ...record, id: doc.id, path: `Manholes/${transaction.id}/Reservoirs/${doc.id}` });
            }
          }
        });
    }
    return () => (mounted = false);
  }, [index]);

  const onChange = (event, name, displayFromTypeahead) => {
    try {
      const id = name || event.target.name;
      const value = typeof name !== 'undefined' ? event : event.target.value;
      const changedRecord = { ...record };
      changedRecord.data[id] = value;
      if (typeof displayFromTypeahead !== 'undefined') {
        changedRecord.data[`${id}Display`] = displayFromTypeahead;
      }
      if (changedRecord.CreatedDate === '') {
        changedRecord.CreatedBy = '';// user.email;
        changedRecord.CreatedDate = new Date().toJSON();
      }
      changedRecord.ModifiedBy = '';// user.email;
      changedRecord.ModifiedDate = new Date().toJSON();
      setRecord(changedRecord);
    } catch (err) {
      toast.error(err.message);
    }
  };

  const addReservoirs = () => {
    firestore
      .collection(`Manholes/${record.ManholeID}/Reservoirs`)
      .doc(`${record.id}`)
      .set(record, { merge: true })
      .then((res) => {
        closeRecord();
      });
  };
  const displayBox = (truthyVal) => truthyVal ? 'none' : 'flex';
  return (
    <>
      <div className="subcollectionButtons">
        <Button variant="contained" color="primary" style={{ marginRight: '5px' }} onClick={addReservoirs}>{(index === '') ? 'Add' : 'Update'} Reservoir</Button>
        <Button variant="contained" onClick={closeRecord}>Close</Button>
      </div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <InputText onChange={onChange} value={record.data.ReservoirID || ''} name="ReservoirID" label="Reservoir ID" />
        </Grid>
        <Grid item xs={12}>
          <InputRadioGroup name="ReservoirConditionBad" value={record.data.ReservoirConditionBad || ''} label="Is the Reservoir Condition Bad?" onChange={onChange}>
            <InputRadio value="Yes" label="Yes" />
            <InputRadio value="No" label="No" />
          </InputRadioGroup>
        </Grid>
        <Grid item xs={12} hidden={record.data.ReservoirConditionBad !== 'Yes'}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={4}>
              <InputRadioGroup name="ReservoirRust" value={record.data.ReservoirRust || ''} label="Does the Reservoir Have Rust?" onChange={onChange}>
                <InputRadio value="Yes" label="Yes" hidden />
                <InputRadio value="No" label="No" />
                <InputRadio value="Minor" label="Minor" />
                <InputRadio value="Severe" label="Severe" />
              </InputRadioGroup>
            </Grid>
            <Grid item xs={4}>
              <InputRadioGroup name="ReservoirLeaking" value={record.data.ReservoirLeaking || ''} label="Is the Reservoir Leaking?" onChange={onChange}>
                <InputRadio value="Yes" label="Yes" hidden />
                <InputRadio value="No" label="No" />
                <InputRadio value="Minor" label="Minor" />
                <InputRadio value="Severe" label="Severe" />
              </InputRadioGroup>
            </Grid>
            <Grid item xs={4}>
              <InputRadioGroup name="ReservoirGroundPoor" value={record.data.ReservoirGroundPoor || ''} label="Is the #2 Ground Poor?" onChange={onChange}>
                <InputRadio value="Yes" label="Yes" />
                <InputRadio value="No" label="No" />
              </InputRadioGroup>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <InputAutocomplete
            label="Gauges"
            name="ReservoirGauges"
            onChange={onChange}
            value={record.data.ReservoirGauges}
            optionDisplay={['option_GaugeID', ' - ', 'option_GaugeType']}
            valueKey="id"
            collection={`Manholes/${transaction.id}/Gauges`}
            required
            multiple
            firestore={firestore}
            enterprise={false}
          />
        </Grid>
        <Grid item xs={12}>
          <InputRadioGroup name="ReservoirType" value={record.data.ReservoirType || ''} label="Reservoir Type" onChange={onChange}>
            <InputRadio value="Gravity" label="Gravity" />
            <InputRadio value="Stand Up" label="Stand Up" />
          </InputRadioGroup>
        </Grid>
        <Grid item xs={12}>
          <InputTextarea name="ReservoirConditionsComments" value={record.data.ReservoirConditionsComments} label="Reservoir Conditions or Comments" onChange={onChange} />
        </Grid>
      </Grid>
    </>
  );
};

ReservoirsForm.propTypes = {
  transaction: PropTypes.objectOf(PropTypes.any),
  setTransaction: PropTypes.func.isRequired,
  index: PropTypes.string.isRequired,
  closeRecord: PropTypes.func.isRequired
};
ReservoirsForm.defaultProps = {
  transaction: {}
};

const Reservoirs = (props) => {
  const { transaction, setTransaction, setShowButtons } = props;
  const [view, setView] = useState('table');
  const [recordId, setRecordId] = useState('');
  const [reservoirs, setReservoirs] = useState([]);

  const handleSelect = (rid) => {
    setRecordId(rid);
    setView('form');
    setShowButtons(false);
  };
  const handleNew = () => {
    setView('form');
    setShowButtons(false);
  };
  const closeRecord = () => {
    setView('table');
    setRecordId('');
    setShowButtons(true);
  };
  useEffect(() => {
    firestore.collection(`Manholes/${transaction.id}/Reservoirs`).onSnapshot((res) => {
      const allReservoirs = [];
      res.forEach((doc) => allReservoirs.push(doc.data()));
      setReservoirs(allReservoirs);
    });
  }, [transaction]);

  const removeRecord = async (r) => {
    await firestore
      .collection(`Manholes/${r.ManholeID}/Reservoirs`)
      .doc(r.id)
      .delete();

    toast.success('Gauge Deleted.');
  };
  const [modal, setModalOpen, setModalProps] = useModalState({
    open: false,
    title: 'Delete Reservoir',
    message: 'Would you like to delete this Reservoir?',
    record: {},
    buttons: [{
      text: 'Delete',
      onClick: (r, { close }) => {
        removeRecord(r);
        close();
      }
    }, {
      text: 'Cancel',
      onClick: (r, { close }) => {
        close()
      }
    }],
    onBackdropClick: ({ close }) => {
      close()
    }
  });
  const handleDelete = (record) => {
    setModalProps({ record })
    setModalOpen(true);
  };
  const selectView = () => {
    if (view === 'table') {
      return (
        <>
          <div style={{
            width: '100%', display: 'flex', justifyContent: 'flex-end', marginBottom: '16px'
          }}
          >
            <Button variant="contained" color="primary" onClick={handleNew}>Add Reservoirs</Button>
          </div>
          <ReservoirsList
            reservoirs={reservoirs}
            onSelect={handleSelect}
            removeRecord={handleDelete}
          />
        </>
      );
    }
    return (
      <ReservoirsForm
        index={recordId}
        transaction={transaction}
        setTransaction={setTransaction}
        closeRecord={closeRecord}
      />
    );
  };
  return (
    <>
      <ModalBox modal={modal} />

      {selectView()}
    </>

  );
};

Reservoirs.propTypes = {
  transaction: PropTypes.objectOf(PropTypes.any),
  setTransaction: PropTypes.func,
  setShowButtons: PropTypes.func
};
Reservoirs.defaultProps = {
  transaction: {},
  setTransaction: () => { },
  setShowButtons: () => { }
};
export default Reservoirs;
