/* eslint-disable arrow-body-style */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { Files } from '@aldridge/aldg-data-components';
import { storage, auth, functions, config } from '../firebase';
// import { Files } from '../FileHandler/Files';

const TransmissionPhoto = (props) => {
  const { transaction, disabledUpload } = props;

  return (
    <Files
      label={`Photos for ${transaction.data.General.GridNumber}, Inspected By: ${transaction.data.General.CrewLead} on ${transaction.data.General.InspectionDate_Date}`}
      name="DesignPhotos"
      multiple
      storage={storage}
      folder={`${transaction.data.General.GridNumber}/${transaction.data.General.CrewLead}/${transaction.data.General.InspectionDate_Date}`}
      getFilesFunction="getFiles"
      getSingleFileFunction="getSingleFile"
      bucket={`${config.storageBucket}`}
      uploadText="Attach Files"
      zipText="Download All"
      auth={auth}
      functions={functions}
      disabled={disabledUpload}
    />
  );
};

TransmissionPhoto.propTypes = {
  transaction: PropTypes.objectOf(PropTypes.any),
  disabledUpload: PropTypes.bool,
};
TransmissionPhoto.defaultProps = {
  transaction: {},
  disabledUpload: false,
};

export default TransmissionPhoto;
