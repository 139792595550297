/* eslint-disable no-unreachable */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, {
  useEffect, useRef, useState, useContext
} from 'react';
import { useRecoilState } from 'recoil';
import PropTypes from 'prop-types';
import {
  Grid,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { Search } from '@aldridge/aldg-data-components';

import { _CurrentGridNumber, _CurrentLegacyGridNumber } from '../_Recoil/atoms';
import { firestore } from '../firebase';
import { UserContext } from '../providers/UserProvider';
import TransmissionTable from './TransmissionTable';

const TransmissionList = (props) => {
  const { history } = props;
  const user = useContext(UserContext);
  const [Transmissions, setTransmissions] = useState([]);
  const [TransmissionsLoading, setTransmissionsLoading] = useState(false);

  const [filtersOpen, setFiltersOpen] = useState(false);
  const isComponentMounted = useRef(true);
  const LASTDOC = useRef(undefined);

  const [CurrentGridNumber, SetCurrentGridNumber] = useRecoilState(
    _CurrentGridNumber
  );
  const [CurrentLegacyGridNumber, SetCurrentLegacyGridNumber] = useRecoilState(
    _CurrentLegacyGridNumber
  );

  const [QueryOptions, setQueryOptions] = useState({
    limit: 100,
    offset: 1,
    sort: null,
    direction: null,

    startAfter: null,
    endAt: null,
    startAt: null,
    lastEndAt: null,
    endBefore: null,

    GridNumber: CurrentGridNumber,
    LegacyGridNumber: CurrentLegacyGridNumber,
  });
  const GridNumberQuery = {
    name: 'GridNumber',
    options: {
      sort: 'GridNumber',
    },
    isMounted: isComponentMounted,
    initData: [],
    mergeField: 'GridNumber',
  };
  const LegacyGridNumberQuery = {
    name: 'LegacyGridNumber',
    options: {
      sort: 'LegacyGridNumber',
    },
    isMounted: isComponentMounted,
    initData: [],
    mergeField: 'LegacyGridNumber',
  };
  useEffect(() => {
    try {
      setTransmissionsLoading(true);
      const colRef = (() => {
        let ref = firestore.collection('Manholes');

        if (QueryOptions.LegacyGridNumber !== null) {
          ref = ref.where(
            'data.General.LegacyGridNumber',
            '==',
            QueryOptions.LegacyGridNumber
          );
        }
        if (QueryOptions.GridNumber !== null) {
          ref = ref.where(
            'data.General.GridNumber',
            '==',
            QueryOptions.GridNumber
          );
        }
        if (QueryOptions.sort !== null) {
          ref = ref.orderBy(
            QueryOptions.sort,
            QueryOptions.direction.toLowerCase()
          );
          ref = ref.orderBy('id', 'asc');
        } else {
          ref = ref.orderBy('id', 'asc');
        }
        if (QueryOptions.startAfter !== null) {
          if (QueryOptions.sort !== null) {
            ref = ref.startAfter(QueryOptions.lastDoc);
          }
          ref = ref.startAfter(QueryOptions.startAfter);
        }
        if (QueryOptions.endBefore !== null) {
          ref = ref.endBefore(QueryOptions.endBefore);
        }
        if (QueryOptions.endBefore !== null) {
          ref = ref.limitToLast(QueryOptions.limit);
        } else {
          ref = ref.limit(QueryOptions.limit);
        }
        return ref;
      })();
      colRef
        .onSnapshot(
          (querySnapshot) => {
            setTransmissionsLoading(true);
            const allTransmissions = [];
            querySnapshot.forEach((doc) => {
              allTransmissions.push(doc.data());
            });
            if (allTransmissions.length > 0) {
              LASTDOC.current = allTransmissions[allTransmissions.length - 1].id;
            }
            setTransmissions(allTransmissions);
            setTransmissionsLoading(false);
            if (
              allTransmissions.length === 0
              && QueryOptions.endBefore !== null
            ) {
              setQueryOptions({ ...QueryOptions, endBefore: null });
            }
            if (
              allTransmissions.length === 0
              && allTransmissions.startAfter !== null
            ) {
              setQueryOptions({ ...QueryOptions, startAfter: null });
            }
          },
          (err) => {
            console.log(err.message);
            toast.error(err.message);
          }
        );
    } catch (err) {
      console.log(err.message);
      toast.error(err.message);
    }
  }, [
    QueryOptions.limit,
    QueryOptions.offset,
    QueryOptions.sort,
    QueryOptions.direction,
    QueryOptions.GridNumber,
    QueryOptions.LegacyGridNumber,

    QueryOptions.lastDoc,
    QueryOptions.endAt,
    QueryOptions.startAfter,
    QueryOptions.lastEndAt,
    QueryOptions.startAt,
    QueryOptions.endBefore,
  ]);

  const handleSelect = (recordId) => {
    history.push(`/transmission/${recordId}`);
  };
  const FilterGridNumber = (obj) => {
    SetCurrentGridNumber(obj);
    const queryOptions = { ...QueryOptions };
    queryOptions.limit = 100;
    queryOptions.offset = 0;
    queryOptions.sort = null;
    queryOptions.direction = null;
    queryOptions.lastDoc = null;
    queryOptions.GridNumber = obj;
    queryOptions.LegacyGridNumber = CurrentLegacyGridNumber;
    setQueryOptions(queryOptions);
  };
  const FilterLegacyGridNumber = (obj) => {
    SetCurrentLegacyGridNumber(obj);
    const queryOptions = { ...QueryOptions };
    queryOptions.limit = 100;
    queryOptions.offset = 0;
    queryOptions.sort = null;
    queryOptions.direction = null;
    queryOptions.lastDoc = null;
    queryOptions.GridNumber = CurrentGridNumber;
    queryOptions.LegacyGridNumber = obj;
    setQueryOptions(queryOptions);
  };

  return (
    <>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
          marginBottom: '16px',
        }}
      >
        {user.admin ? <Link to="/transmission/new">
          <Button variant="contained" color="primary">
            Add New
          </Button>
        </Link> : null}
      </div>
      <div
        style={{
          width: '100%',
          marginBottom: '16px',
        }}
      >
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            Filters
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item md={6} xs={12}>
                <Search
                  label="Grid Number"
                  type="GridNumber"
                  query={GridNumberQuery}
                  searchfnc={FilterGridNumber}
                  name="GridNumber"
                  valueKey="GridNumber"
                  optionDisplay={['option_GridNumber']}
                  firestore={firestore}
                  value={
                    CurrentGridNumber !== -1 ? CurrentGridNumber : undefined
                  }
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Search
                  label="Legacy Grid Number"
                  type="LegacyGridNumber"
                  query={LegacyGridNumberQuery}
                  searchfnc={FilterLegacyGridNumber}
                  name="LegacyGridNumber"
                  valueKey="LegacyGridNumber"
                  firestore={firestore}
                  optionDisplay={['option_LegacyGridNumber']}
                  value={
                    CurrentLegacyGridNumber !== ''
                      ? CurrentLegacyGridNumber
                      : undefined
                  }
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </div>
      <Grid container>
        <TransmissionTable
          Transmissions={Transmissions}
          queryOptions={QueryOptions}
          onSelect={handleSelect}
        />
      </Grid>
    </>
  );
};

TransmissionList.propTypes = {};
TransmissionList.defaultProps = {};

export default TransmissionList;
