import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Loader from 'react-loader-spinner';
import { toast } from 'react-toastify';
import { firestore } from '../firebase';
import Wizard from './Wizard';
import TransmissionPhoto from './TransmissionPhoto';
import SummaryReport from './SummaryReport';
import TransmissionPDF from './TransmissionPdf';

const PepcoImageViewer = ({ match }) => {
  const [transaction, setTransaction] = useState({});

  useEffect(() => {
    firestore
      .collection(`Manholes`)
      .doc(match.params.id)
      .get()
      .then((res) => {
        if (res.exists) {
          setTransaction(res.data());
        } else {
          toast.error('This Manhole cannot be found.');
        }
      });
  }, [match.params.id]);
  return (
    <>
      {typeof transaction.id !== 'undefined' ? (
        <Wizard styles={{ position: 'sticky', zIndex: '99' }} steps={[
          {
            name: 'Files',
            component: <TransmissionPhoto transaction={transaction} disabledUpload />,
          },
          {
            name: 'Priority Summary',
            component: <SummaryReport transaction={transaction} />,
          },
          {
            name: 'PDF',
            component: <TransmissionPDF transaction={transaction} hideUpload />,
          },

        ]} />
      ) : (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            flexDirection: 'column',
          }}
        >
          <Loader type="TailSpin" />
          <div>Loading Manhole Data...</div>
        </div>
      )}
    </>
  );
};

PepcoImageViewer.propTypes = {
  match: PropTypes.objectOf(PropTypes.any),
};
PepcoImageViewer.defaultProps = {
  match: {},
};

export default PepcoImageViewer;
