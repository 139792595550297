/* eslint-disable no-unused-vars */
import React, { useContext } from 'react';
import { Switch, Route } from 'react-router-dom';
import { UserContext } from '../providers/UserProvider';

import SignIn from './SignIn';
import Unauthorized from './Unauthorized';
import NoMatch from './NoMatch';
import CableWizard from './CableWizard';
import TransmissionList from './TransmissionList';
import UserManagement from './UserManagement';
import PepcoImageViewer from './PepcoImageViewer';
import DataWork from './DataWork';

const Application = () => {
  const user = useContext(UserContext);
  const page = () => {
    // return <Test />
    // eslint-disable-next-line no-unreachable
    if (!user) {
      return <SignIn />;
    }
    if (user && (user.admin || user.user)) {
      return (
        <Switch>
          <Route exact path="/" component={TransmissionList} />
          <Route exact path="/users" component={UserManagement} />
          <Route exact path="/transmission" component={TransmissionList} />
          <Route exact path="/transmission/new" component={CableWizard} />
          <Route exact path="/transmission/:id" component={CableWizard} />
          <Route exact path="/test" component={DataWork} />
          <Route exact path="/files/:id" component={PepcoImageViewer} />
          <Route exact>
            <NoMatch />
          </Route>
        </Switch>
      );
    }
    if (user && user.viewer) {
      return (
        <Switch>
          <Route exact path="/files/:id" component={PepcoImageViewer} />
          <Route exact>
            <Unauthorized />
          </Route>
        </Switch>
      );
    }

    return <Unauthorized />;
  };

  return (
    <>
      <div className="appBody">{page()}</div>
    </>
  );
};
export default Application;
