/* eslint-disable no-underscore-dangle */
import { atom } from 'recoil';

export const _CurrentGridNumber = atom({
  key: 'CurrentGridNumber',
  default: null
});

export const _CurrentLegacyGridNumber = atom({
  key: 'CurrentLegacyGridNumber',
  default: null
});
