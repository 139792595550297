import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card, Stepper, Step, StepLabel, CardContent
} from '@material-ui/core';
import { toast } from 'react-toastify';

const Wizard = (props) => {
  const { steps, saveOnChange, styles } = props;

  const [currentIdx, setCurrentIdx] = useState(0);
  const stepperStyles = styles || { position: 'sticky', top: '46px', zIndex: '99' };
  return (
    <>
      <Stepper activeStep={currentIdx} alternativeLabel style={stepperStyles}>
        {steps.map((label, index) => (
          <Step key={label.name} disabled={label.disabled} onClick={() => { if (label.disabled) { toast.info('Make sure to finish your current step before moving on.'); return; } setCurrentIdx(index); if (typeof saveOnChange === 'function') saveOnChange(); }} hidden={typeof label.isVisible === 'undefined' ? false : !label.isVisible}>
            <StepLabel style={{ cursor: 'pointer' }}>{label.name}</StepLabel>
          </Step>
        ))}
      </Stepper>

      <div className="wizardContainer">
        {
          steps.length > 0 ? steps.map((c, idx) => (
            <Card hidden={currentIdx !== idx} style={{ width: '100%' }} key={c.name}>
              <CardContent>
                {React.cloneElement(c.component, { ...props })}
              </CardContent>
            </Card>
          )) : <></>
        }
      </div>
    </>
  );
};

Wizard.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    component: PropTypes.objectOf(PropTypes.any),
    isVisible: PropTypes.bool,
    disabled: PropTypes.bool
  })).isRequired,
  saveOnChange: PropTypes.func,
  styles: PropTypes.objectOf(PropTypes.any)
};

Wizard.defaultProps = {
  saveOnChange: undefined,
  styles: undefined
};

export default Wizard;
