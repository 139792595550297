/* eslint-disable no-unused-vars */
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Toolbar from '@material-ui/core/Toolbar';
import MenuItem from '@material-ui/core/MenuItem';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import {
  Typography,
  IconButton,
  AppBar,
  Drawer,
  Divider,
  List,
  ListItem,
} from '@material-ui/core';

import { UserContext } from '../providers/UserProvider';

const Header = (props) => {
  const user = useContext(UserContext);
  const { appTitle } = props;

  const MenuOptions = (type) => {
    if (type === 'menu') {
      return (
        <>
          {user && user.SuperAdmin ? (
            <>
              <MenuItem>
                <Link to="/users">User Management</Link>
              </MenuItem>
            </>
          ) : null}
          {user && (user.user || user.admin) ? (
            <>
              <MenuItem>
                <Link to="/transmission">Tranmission Holes</Link>
              </MenuItem>
            </>
          ) : null}
        </>
      );
    }
    if (type === 'drawer') {
      return (
        <>
          {user && user.SuperAdmin ? (
            <>
              <ListItem button>
                <Link to="/users">User Management</Link>
              </ListItem>
            </>
          ) : null}
          {user && (user.user || user.admin) ? (
            <>
              <ListItem button>
                <Link to="/transmission">Tranmission Holes</Link>
              </ListItem>
            </>
          ) : null}
        </>
      );
    }
    return null;
  };

  const [open, setOpen] = React.useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const MenuIconClick = () => {};
  return (
    <>
      <AppBar className="header" position="relative">
        <Toolbar className="navbar">
          <Typography className="brand">{appTitle}</Typography>
          <div className="menuLinks navbar-collapse collapse">
            {MenuOptions('menu')}
          </div>
          <IconButton className="collapsedMenuIcon" onClick={handleDrawerOpen}>
            <MenuIcon />
          </IconButton>
        </Toolbar>

        <div className="brandContainer">
          <div className="brandBar" />
          <a href="https://www.aldridgegroup.com/">aldridgegroup.com</a>
        </div>
      </AppBar>
      <Drawer
        anchor="right"
        open={open}
        ModalProps={{ onBackdropClick: handleDrawerClose }}
      >
        <div>
          <IconButton onClick={handleDrawerClose}>
            <ChevronRightIcon />
          </IconButton>
        </div>
        <Divider />
        <List className="drawerLinks">{MenuOptions('drawer')}</List>
      </Drawer>
    </>
  );
};

Header.propTypes = {
  appTitle: PropTypes.string,
};
Header.defaultProps = {
  appTitle: '',
};

export default Header;
