/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  InputAdornment,
  Grid,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Button,
  TableBody,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  makeStyles
} from '@material-ui/core';
import { toast } from 'react-toastify';
import { Delete } from '@material-ui/icons';

import {
  InputDate, InputText, InputRadio, InputRadioGroup, InputNumber, InputTextarea, InputAutocomplete
} from '@aldridge/aldg-data-components';
import { ModalBox, useModalState } from '@aldridge/aldg-confirm';
import { firestore } from '../firebase';
import Conduits from './Conduits';

import { UserContext } from '../providers/UserProvider';

const DuctBanksList = (props) => {
  const { reservoirs, onSelect, removeRecord } = props;

  return (
    <>
      <Grid container spacing={3}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Location</TableCell>
                <TableCell>Width</TableCell>
                <TableCell>Height</TableCell>
                <TableCell>{' '}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {reservoirs.map((row, idx) => (
                <TableRow style={{ cursor: 'pointer' }} hover onClick={() => onSelect(row.id)} key={row.id}>
                  <TableCell>{row.data.DuctLocation}</TableCell>
                  <TableCell>{row.data.DuctWidth}</TableCell>
                  <TableCell>{row.data.DuctHeight}</TableCell>
                  <TableCell align="right"><Delete onClick={(e) => { e.stopPropagation(); removeRecord(row); }} /></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </>
  );
};

DuctBanksList.propTypes = {
  reservoirs: PropTypes.arrayOf(PropTypes.any),
  onSelect: PropTypes.func,
  removeRecord: PropTypes.func.isRequired
};
DuctBanksList.defaultProps = {
  reservoirs: [],
  onSelect: () => { }
};

const DuctBanksForm = (props) => {
  const user = useContext(UserContext);
  const {
    transaction, setTransaction, index, closeRecord
  } = props;
  const [ductRecord, setDuctRecord] = useState(index);
  const [conduits, setConduits] = useState([]);
  const [record, setRecord] = useState({
    id: '',
    ManholeID: transaction.id,
    path: `Manholes/${transaction.id}/DuctBanks/${index}`,
    collection: 'DuctBanks',
    CreatedBy: '',
    CreatedDate: '',
    ModifiedBy: '',
    ModifiedDate: '',
    data: {
      DuctLocation: '',
      DuctWidth: '',
      DuctHeight: '',
      DuctBankConditionsComments: '',
    }
  });

  useEffect(() => {
    if (ductRecord === '') {
      const docRef = firestore
        .collection(`Manholes/${record.ManholeID}/DuctBanks`)
        .doc();
      setRecord({ ...record, id: docRef.id, path: `Manholes/${transaction.id}/DuctBanks/${docRef.id}` });
    } else {
      firestore
        .collection(`Manholes/${record.ManholeID}/DuctBanks`)
        .doc(ductRecord)
        .onSnapshot((doc) => {
          if (doc.exists) {
            const r = doc.data();
            setRecord(r);
            firestore
              .collection(`Manholes/${record.ManholeID}/DuctBanks/${r.id}/Conduits`)
              .orderBy('data.ConduitPosition')
              .onSnapshot((con) => {
                const docs = [];
                con.forEach((c) => {
                  docs.push(c.data());
                });
                setConduits(docs);
              }, (err) => {
                console.log(err);
              });
          } else {
            setRecord({ ...record, id: doc.id, path: `Manholes/${transaction.id}/DuctBanks/${doc.id}` });
          }
        });
    }
  }, [ductRecord]);

  const onChange = (event, name, displayFromTypeahead) => {
    try {
      const id = name || event.target.name;
      const value = typeof name !== 'undefined' ? event : event.target.value;
      const changedRecord = { ...record };
      changedRecord.data[id] = value;
      if (typeof displayFromTypeahead !== 'undefined') {
        changedRecord.data[`${id}Display`] = displayFromTypeahead;
      }
      if (changedRecord.CreatedDate === '') {
        changedRecord.CreatedBy = '';// user.email;
        changedRecord.CreatedDate = new Date().toJSON();
      }
      changedRecord.ModifiedBy = '';// user.email;
      changedRecord.ModifiedDate = new Date().toJSON();
      setRecord(changedRecord);
    } catch (err) {
      toast.error(err.message);
    }
  };
  const addDuctBanks = () => {
    firestore
      .collection(`Manholes/${record.ManholeID}/DuctBanks`)
      .doc(`${record.id}`)
      .set(record, { merge: true })
      .then((res) => {
        toast.success('Duct Bank Saved.');
        setDuctRecord(record.id);
        // closeRecord();
      });
  };
  const [deleteState, setDeleteState] = useState({
    proceedLabel: 'Delete',
    cancelLabel: 'Cancel',
    title: 'Delete Existing Conduits',
    modalBody: 'Are you sure you want to re-create all the conduits? All completed conduits will be deleted',
    show: false,
    // eslint-disable-next-line no-use-before-define
    proceed: (approved, r) => { removeRecord(approved, r); },
    enableEscape: true
  });
  const generateConduits = (rec) => {
    const r = rec || record;
    const createConduits = () => {
      const width = r.data.DuctWidth;
      const height = r.data.DuctHeight;
      const c = [];
      for (let h = 1; h <= height; h++) {
        for (let w = 1; w <= width; w++) {
          const pos = `${(h < 10 ? '0' : '') + h}T - ${(w < 10 ? '0' : '') + w}W`;
          c.push(pos);
        }
      }
      c.forEach(async (conduit) => {
        const docRef = firestore.collection(`Manholes/${r.ManholeID}/DuctBanks/${r.id}/Conduits`).doc();
        const newConduit = {
          id: docRef.id,
          ManholeID: r.ManholeID,
          DuctBankID: r.id,
          path: `Manholes/${r.ManholeID}/DuctBanks/${r.id}/Conduits/${docRef.id}`,
          collection: 'Conduits',
          CreatedBy: '',
          CreatedDate: '',
          ModifiedBy: '',
          ModifiedDate: '',
          data: {
            ConduitPosition: conduit,
            Contents: 'Empty',
            CableID: '',
            CableConditionBad: '',
            CableConditionIssues: '',
            CableType: '',
            Joint: '',
            JointType: '',
            JointConditionBad: '',
            ConduitComments: '',
          }
        };
        await docRef.set(newConduit, { merge: true });
      });
    };
    firestore
      .collection(`Manholes/${r.ManholeID}/DuctBanks/${r.id}/Conduits`)
      .get()
      .then((res) => {
        if (res.empty) {
          createConduits();
        } else {
          // eslint-disable-next-line no-use-before-define
          setConduitModalProps({ record });
          // eslint-disable-next-line no-use-before-define
          setConduitModalOpen(true);
        }
      });
  };
  const removeRecord = async (r) => {
    firestore.collection(`${r.path}/Conduits`).get().then((res) => {
      const arr = [];
      res.forEach((doc) => {
        arr.push(doc.data().id);
      });
      arr.forEach(async (c) => {
        await firestore.collection(`${r.path}/Conduits`).doc(c).delete();
      });

      generateConduits(r);
    });
  };
  const accordionClass = makeStyles({
    root: {
      border: '1px solid #ccc',
      '&:not(:last-child)': {
        borderBottom: 0,
      },
      '&$expanded': {
        border: '1px solid #2a56a7',
        margin: '8px 0'
      }
    },
    expanded: {}
  })();
  const accordionSummaryClass = makeStyles({
    root: {
      backgroundColor: '#eee',
      boxShadow: 'inset 0px -19px 4px -19px #aaa',
      '&$expanded': {
        maxHeight: '48px',
        minHeight: '48px',
        backgroundColor: 'rgb(59, 120, 231)',
        color: 'white',
        // border: '1px solid #ccc',
      }
    },
    expanded: {}
  })();
  const accordionDetailsClass = makeStyles({
    root: {
      flexDirection: 'column'
    },
  })();
  const [conduitModal, setConduitModalOpen, setConduitModalProps] = useModalState({
    open: false,
    title: 'Delete Conduits',
    message: 'Would you like to delete and re-create these conduits?',
    record: {},
    buttons: [{
      text: 'Delete',
      onClick: (r, { close }) => {
        removeRecord(r);
        close();
      }
    }, {
      text: 'Cancel',
      onClick: (r, { close }) => {
        close()
      }
    }],
    onBackdropClick: ({ close }) => {
      close()
    }
  });
  return (
    <>
      <ModalBox modal={conduitModal} />
      <div className="subcollectionButtons">
        <Button variant="contained" color="primary" style={{ marginRight: '5px' }} onClick={addDuctBanks}>{(index === '') ? 'Add' : 'Update'} Duct Bank</Button>
        <Button variant="contained" onClick={closeRecord}>Close</Button>
      </div>
      <Grid container spacing={3}>
        <Grid item md={12}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={6}>
              <InputRadioGroup name="DuctLocation" value={record.data.DuctLocation || ''} label="Duct Bank Location" onChange={onChange}>
                <InputRadio value="North Wall" label="North Wall" />
                <InputRadio value="South Wall" label="South Wall" />
                <InputRadio value="East Wall" label="East Wall" />
                <InputRadio value="West Wall" label="West Wall" />
                <InputRadio value="Floor" label="Floor" />
              </InputRadioGroup>
            </Grid>
            <Grid item xs={6}>
              <Grid item xs={12}>
                <InputNumber label="Width" name="DuctWidth" onChange={onChange} value={record.data.DuctWidth} />
              </Grid>
              <Grid item xs={12}>
                <InputNumber label="Height" name="DuctHeight" onChange={onChange} value={record.data.DuctHeight} />
              </Grid>
              <Grid item xs={12}>
                <Button variant="contained" color="primary" onClick={() => generateConduits()} hidden={(ductRecord === '')}>Generate Conduits</Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={12}>
          <InputTextarea name="DuctBankConditionsComments" value={record.data.DuctBankConditionsComments} label="DuctBank Conditions or Comments" onChange={onChange} />
        </Grid>

        <Grid item xs={12}>
          <Accordion classes={accordionClass}>
            <AccordionSummary classes={accordionSummaryClass}>Conduits</AccordionSummary>
            <AccordionDetails classes={accordionDetailsClass}>
              <Conduits conduits={conduits} ManholeID={record.ManholeID} DuctBankID={record.id} />
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </>
  );
};

DuctBanksForm.propTypes = {
  transaction: PropTypes.objectOf(PropTypes.any),
  setTransaction: PropTypes.func.isRequired,
  index: PropTypes.string.isRequired,
  closeRecord: PropTypes.func.isRequired
};
DuctBanksForm.defaultProps = {
  transaction: {}
};

const DuctBanks = (props) => {
  const { transaction, setTransaction, setShowButtons } = props;
  const [view, setView] = useState('table');
  const [recordId, setRecordId] = useState('');
  const [reservoirs, setDuctBanks] = useState([]);

  const handleSelect = (rid) => {
    setRecordId(rid);
    setView('form');
    setShowButtons(false);
  };
  const handleNew = () => {
    setView('form');
    setShowButtons(false);
  };
  const closeRecord = () => {
    setView('table');
    setRecordId('');
    setShowButtons(true);
  };
  useEffect(() => {
    firestore.collection(`Manholes/${transaction.id}/DuctBanks`).onSnapshot((res) => {
      const allDuctBanks = [];
      res.forEach((doc) => allDuctBanks.push(doc.data()));
      setDuctBanks(allDuctBanks);
    });
  }, [transaction]);

  const removeRecord = async (r) => {
    await firestore
      .collection(`Manholes/${r.ManholeID}/DuctBanks`)
      .doc(r.id)
      .delete();

    toast.success('Gauge Deleted.');
  };
  const [modal, setModalOpen, setModalProps] = useModalState({
    open: false,
    title: 'Delete Duct Bank',
    message: 'Would you like to delete this duct bank and the conduits with it?',
    record: {},
    buttons: [{
      text: 'Delete',
      onClick: (r, { close }) => {
        removeRecord(r);
        close();
      }
    }, {
      text: 'Cancel',
      onClick: (r, { close }) => {
        close()
      }
    }],
    onBackdropClick: ({ close }) => {
      close()
    }
  });
  const handleDelete = (record) => {
    setModalProps({ record })
    setModalOpen(true);
  };

  const selectView = () => {
    if (view === 'table') {
      return (
        <>
          <div style={{
            width: '100%', display: 'flex', justifyContent: 'flex-end', marginBottom: '16px'
          }}
          >
            <Button variant="contained" color="primary" onClick={handleNew}>Add Duct Bank</Button>
          </div>
          <DuctBanksList
            reservoirs={reservoirs}
            onSelect={handleSelect}
            removeRecord={handleDelete}
          />
        </>
      );
    }
    return (
      <DuctBanksForm
        index={recordId}
        transaction={transaction}
        setTransaction={setTransaction}
        closeRecord={closeRecord}
      />
    );
  };
  return (
    <>
      <ModalBox modal={modal} />

      {selectView()}
    </>

  );
};

DuctBanks.propTypes = {
  transaction: PropTypes.objectOf(PropTypes.any),
  setTransaction: PropTypes.func,
  setShowButtons: PropTypes.func
};
DuctBanks.defaultProps = {
  transaction: {},
  setTransaction: () => { },
  setShowButtons: () => { }
};

export default DuctBanks;
