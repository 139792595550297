/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  InputAdornment,
  Grid,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Button,
  TableBody,
  Box,
} from '@material-ui/core';
import { toast } from 'react-toastify';
import { Delete } from '@material-ui/icons';

import {
  InputDate,
  InputText,
  InputRadio,
  InputRadioGroup,
  InputNumber,
  InputTextarea,
} from '@aldridge/aldg-data-components';
import { ModalBox, useModalState } from '@aldridge/aldg-confirm';
import { firestore } from '../firebase';
import { UserContext } from '../providers/UserProvider';

const GaugesList = (props) => {
  const { gauges, onSelect, removeRecord } = props;

  return (
    <>
      <Grid container spacing={3}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Gauge ID</TableCell>
                <TableCell> </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {gauges.map((row, idx) => (
                <TableRow
                  style={{ cursor: 'pointer' }}
                  hover
                  onClick={() => onSelect(row.id)}
                  key={row.id}
                >
                  <TableCell>{row.data.GaugeID}</TableCell>
                  <TableCell align="right">
                    <Delete
                      onClick={(e) => {
                        e.stopPropagation();
                        removeRecord(row);
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </>
  );
};

GaugesList.propTypes = {
  gauges: PropTypes.arrayOf(PropTypes.any),
  onSelect: PropTypes.func,
  removeRecord: PropTypes.func.isRequired,
};
GaugesList.defaultProps = {
  gauges: [],
  onSelect: () => { },
};

const GaugesForm = (props) => {
  const { transaction, setTransaction, index, closeRecord } = props;
  const user = useContext(UserContext);
  const [record, setRecord] = useState({
    id: '',
    ManholeID: transaction.id,
    path: `Manholes/${transaction.id}/Gauges/${index}`,
    collection: 'Gauges',
    CreatedBy: '',
    CreatedDate: '',
    ModifiedBy: '',
    ModifiedDate: '',
    data: {
      GaugeID: '',
      GaugeCondition: '',
      GaugeBroken: '',
      GaugeLeaking: '',
      GaugeType: '',
      GaugesComments: '',
    },
  });

  useEffect(() => {
    let mounted = true;
    if (index === '') {
      const docRef = firestore
        .collection(`Manholes/${record.ManholeID}/Gauges`)
        .doc();
      if (mounted)
        setRecord({
          ...record,
          id: docRef.id,
          path: `Manholes/${transaction.id}/Gauges/${docRef.id}`,
        });
    } else {
      firestore
        .collection(`Manholes/${record.ManholeID}/Gauges`)
        .doc(index)
        .onSnapshot((doc) => {
          if (mounted) {
            if (doc.exists) {
              setRecord(doc.data());
            } else {
              setRecord({
                ...record,
                id: doc.id,
                path: `Manholes/${transaction.id}/Gauges/${doc.id}`,
              });
            }
          }
        });
    }
    return () => (mounted = false);
  }, [index]);

  const onChange = (event, name, displayFromTypeahead) => {
    try {
      const id = name || event.target.name;
      const value = typeof name !== 'undefined' ? event : event.target.value;
      const changedRecord = { ...record };
      changedRecord.data[id] = value;
      if (typeof displayFromTypeahead !== 'undefined') {
        changedRecord.data[`${id}Display`] = displayFromTypeahead;
      }
      if (changedRecord.CreatedDate === '') {
        changedRecord.CreatedBy = ''; // user.email;
        changedRecord.CreatedDate = new Date().toJSON();
      }
      changedRecord.ModifiedBy = ''; // user.email;
      changedRecord.ModifiedDate = new Date().toJSON();
      setRecord(changedRecord);
    } catch (err) {
      toast.error(err.message);
    }
  };

  const addGauges = () => {
    firestore
      .collection(`Manholes/${record.ManholeID}/Gauges`)
      .doc(`${record.id}`)
      .set(record, { merge: true })
      .then((res) => {
        closeRecord();
      });
  };
  const displayBox = (truthyVal) => (truthyVal ? 'none' : 'flex');
  return (
    <>
      <div className="subcollectionButtons">
        <Button
          variant="contained"
          color="primary"
          style={{ marginRight: '5px' }}
          onClick={addGauges}
        >
          {index === '' ? 'Add' : 'Update'} Gauges
        </Button>
        <Button variant="contained" onClick={closeRecord}>
          Close
        </Button>
      </div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <InputText
            onChange={onChange}
            value={record.data.GaugeID || ''}
            name="GaugeID"
            label="Gauge ID"
          />
        </Grid>
        <Grid item xs={12} style={{ paddingTop: '16px' }}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={6}>
              <InputRadioGroup
                name="GaugeCondition"
                value={record.data.GaugeCondition || ''}
                label="Is the Gauge Condition Bad?"
                onChange={onChange}
              >
                <InputRadio value="Yes" label="Yes" />
                <InputRadio value="No" label="No" />
              </InputRadioGroup>
            </Grid>
            <Grid item xs={6}>
              <Box
                component={Grid}
                display={displayBox(!(record.data.GaugeCondition === 'Yes'))}
                item
                xs={12}
              >
                <Grid item xs={4}>
                  <InputRadioGroup
                    name="GaugeBroken"
                    value={record.data.GaugeBroken || ''}
                    label="Is the Gauge Broken?"
                    onChange={onChange}
                  >
                    <InputRadio value="Yes" label="Yes" />
                    <InputRadio value="No" label="No" />
                  </InputRadioGroup>
                </Grid>
                <Grid item xs={4}>
                  <InputRadioGroup
                    name="GaugeLeaking"
                    value={record.data.GaugeLeaking || ''}
                    label="Is the Gauge Leaking?"
                    onChange={onChange}
                  >
                    <InputRadio value="Yes" label="Yes" hidden />
                    <InputRadio value="No" label="No" />
                    <InputRadio value="Minor" label="Minor" />
                    <InputRadio value="Severe" label="Severe" />
                  </InputRadioGroup>
                </Grid>
                <Grid item xs={4}>
                  <InputRadioGroup
                    name="ImmediateSafetyIssue"
                    hidden={
                      record.data.GaugeLeaking === 'No' ||
                      record.data.GaugeLeaking === ''
                    }
                    value={record.data.ImmediateSafetyIssue || ''}
                    label="Immediate Safety Issue?"
                    onChange={onChange}
                  >
                    <InputRadio value="Yes" label="Yes" />
                    <InputRadio value="No" label="No" />
                  </InputRadioGroup>
                </Grid>
              </Box>
            </Grid>

            <Grid item xs={6}>
              <InputRadioGroup
                name="GaugeType"
                value={record.data.GaugeType || ''}
                label="What's the Gauge Type?"
                onChange={onChange}
              >
                <InputRadio value="High-Low" label="High-Low" />
                <InputRadio value="Emergency Low" label="Emergency Low" />
                <InputRadio value="Gravity" label="Gravity" />
                <InputRadio value="Legacy Gravity" label="Legacy Gravity" />
              </InputRadioGroup>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <InputTextarea
            name="GaugeConditionsComments"
            value={record.data.GaugeConditionsComments}
            label="Gauge Conditions or Comments"
            onChange={onChange}
          />
        </Grid>
      </Grid>
    </>
  );
};

GaugesForm.propTypes = {
  transaction: PropTypes.objectOf(PropTypes.any),
  setTransaction: PropTypes.func.isRequired,
  index: PropTypes.string.isRequired,
  closeRecord: PropTypes.func.isRequired,
};
GaugesForm.defaultProps = {
  transaction: {},
};

const Gauges = (props) => {
  const { transaction, setTransaction, setShowButtons } = props;
  const [view, setView] = useState('table');
  const [recordId, setRecordId] = useState('');
  const [gauges, setGauges] = useState([]);

  const handleSelect = (rid) => {
    setRecordId(rid);
    setView('form');
    setShowButtons(false);
  };
  const handleNew = () => {
    setView('form');
    setShowButtons(false);
  };
  const closeRecord = () => {
    setView('table');
    setRecordId('');
    setShowButtons(true);
  };
  useEffect(() => {
    firestore
      .collection(`Manholes/${transaction.id}/Gauges`)
      .onSnapshot((res) => {
        const allGauges = [];
        res.forEach((doc) => allGauges.push(doc.data()));
        setGauges(allGauges);
      });
  }, [transaction]);

  const removeRecord = async (r) => {
    await firestore
      .collection(`Manholes/${r.ManholeID}/Gauges`)
      .doc(r.id)
      .delete();

    toast.success('Gauge Deleted.');
  };
  const [modal, setModalOpen, setModalProps] = useModalState({
    open: false,
    title: 'Delete Gauge',
    message: 'Would you like to delete this gauge?',
    record: {},
    buttons: [
      {
        text: 'Delete',
        onClick: (r, { close }) => {
          removeRecord(r);
          close();
        },
      },
      {
        text: 'Cancel',
        onClick: (r, { close }) => {
          close();
        },
      },
    ],
    onBackdropClick: ({ close }) => {
      close();
    },
  });
  const handleDelete = (record) => {
    setModalProps({ record });
    setModalOpen(true);
  };
  const selectView = () => {
    if (view === 'table') {
      return (
        <>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
              marginBottom: '16px',
            }}
          >
            <Button variant="contained" color="primary" onClick={handleNew}>
              Add Gauges
            </Button>
          </div>
          <GaugesList
            gauges={gauges}
            onSelect={handleSelect}
            removeRecord={handleDelete}
          />
        </>
      );
    }
    return (
      <GaugesForm
        index={recordId}
        transaction={transaction}
        setTransaction={setTransaction}
        closeRecord={closeRecord}
      />
    );
  };
  return (
    <>
      <ModalBox modal={modal} />

      {selectView()}
    </>
  );
};

Gauges.propTypes = {
  transaction: PropTypes.objectOf(PropTypes.any),
  setTransaction: PropTypes.func,
  setShowButtons: PropTypes.func,
};
Gauges.defaultProps = {
  transaction: {},
  setTransaction: () => { },
  setShowButtons: () => { },
};

export default Gauges;
