/* eslint-disable no-unreachable */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, {
  useEffect, useRef, useState, useContext
} from 'react';
import { useRecoilState } from 'recoil';
import PropTypes from 'prop-types';
import {
  Grid,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { Search } from '@aldridge/aldg-data-components';

import { Pannellum } from 'pannellum-react';
import { _CurrentGridNumber, _CurrentLegacyGridNumber } from '../_Recoil/atoms';
import { firestore } from '../firebase';
import { UserContext } from '../providers/UserProvider';
import TransmissionTable from './TransmissionTable';


const DataWork = (props) => {
  const { history } = props;

  const imgSrc = 'https://storage.googleapis.com/aldg-dctransmission.appspot.com/798412-213266%282%29/Levin%20Jamerson/2021-05-21/R0011376.JPG?GoogleAccessId=firebase-adminsdk-2wycq%40aldg-dctransmission.iam.gserviceaccount.com&Expires=1631900217&Signature=vON8c88DUC%2FUpUyQmhztjAS13tdBNw6c0Tly3YQX9fHM8RWp5unJ%2Bx5EIZwF5Y1u4ZOLzpxAsRIKexRzfcipaLNkCOcwWkZFw42MR4eu8TrIy0LMBN%2BEHsGZmATOmVMh4KpvmtI4%2Fd5mBGlfn%2FSKZ0b5KYhd0igEiklJhwfavVh4hAdNLDVwYFuZvIUfZpO8ttMeUg0MWIIme3hIGgXcDM6oJhCZl34%2BKbyqrHFjDeNTuMpVZoXFgs36bytvhO0ao%2FC1EvX7POapO5dtASg26oUalURUtVN2PrI%2BYGf10XmpBoidRFY%2BHQdIRRUhIEZQP%2FL0dq1Ma1Knykk6fWu2yQ%3D%3D';
  const missingGrids = [
    // '786390-041959',
    // '786391-168043',
    // '788395-449083',
    // '791395-000016',
    // '791395-842018',
    // '792396-255404',
    // '792397-243840',
    // '791402-630063',
    // '792404-892222',
    // '792404-992422',
    // '793404-304915',
    // '793405-560352',
    // '793405-564350',
    // '793405-867599',
    // '793405-881609',
    // '791401-536895',
    '793405-899980'
  ]
  useEffect(() => {
    // try {
    //   const colRef = (() => {
    //     let ref = firestore.collection('History/Manholes/Manholes');
    //     ref = ref.where('data.General.GridNumber', '==', '797412-525275')
    //     ref = ref.orderBy('ModifiedDate', 'desc');
    //     return ref;
    //   })();
    //   colRef
    //     .onSnapshot(
    //       (querySnapshot) => {
    //         const allTransmissions = [];
    //         // querySnapshot.forEach((doc) => {
    //         //   // doc.ref.delete();
    //         //   allTransmissions.push(doc.data());
    //         // });
    //         // console.log(allTransmissions);

    //         // firestore.collection('Manholes').doc(allTransmissions[0].id).set(allTransmissions[0], { merge: true })
    //       },
    //       (err) => {
    //         console.log(err.message);
    //         toast.error(err.message);
    //       }
    //     );
    // } catch (err) {
    //   console.log(err.message);
    //   toast.error(err.message);
    // }
  }, []);
  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        backgroundColor: 'white',
        position: 'absolute',
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        zIndex: 2500
      }}
    >
      <Button
        color='primary'
        variant='contained'

      >
        Close
      </Button>
      <Pannellum
        width='100%'
        height='100%'
        image={imgSrc}
        pitch={10}
        yaw={180}
        hfov={110}
        autoLoad
      />
    </div>
  );
};

DataWork.propTypes = {};
DataWork.defaultProps = {};

export default DataWork;
