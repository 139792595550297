import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid, Box
} from '@material-ui/core';
import {
  InputNumber,
  InputRadio, InputRadioGroup, InputTextarea
} from '@aldridge/aldg-data-components';

const TransmissionHoleCableSupport = (props) => {
  const { transaction, onChange } = props;

  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <InputRadioGroup name="CableSupportConditions.MudOnCableSupports" value={transaction.data.CableSupportConditions.MudOnCableSupports || ''} label="Is there Mud on the Cable Supports?" onChange={onChange}>
          <InputRadio value="Yes" label="Yes" />
          <InputRadio value="No" label="No" />
        </InputRadioGroup>
      </Grid>
      <Grid item xs={6}>
        <InputRadioGroup name="CableSupportConditions.CleaningBeforeCM" hidden={!(transaction.data.CableSupportConditions.MudOnCableSupports === 'Yes')} value={transaction.data.CableSupportConditions.CleaningBeforeCM || ''} label="Do They Need to be Cleaned Before Maintenance?" onChange={onChange}>
          <InputRadio value="Yes" label="Yes" />
          <InputRadio value="No" label="No" />
        </InputRadioGroup>
      </Grid>
      <Grid item xs={12}>
        <InputRadioGroup name="CableSupportConditions.AlarmCablesHeldByZipTies" value={transaction.data.CableSupportConditions.AlarmCablesHeldByZipTies || ''} label="Are the Alarm Cables Held By Zip Ties?" onChange={onChange}>
          <InputRadio value="Yes" label="Yes" />
          <InputRadio value="No" label="No" />
        </InputRadioGroup>
      </Grid>
      <Grid item xs={12}>
        <InputRadioGroup name="CableSupportConditions.SupportSystemBad" value={transaction.data.CableSupportConditions.SupportSystemBad || ''} label="Are the Support Systems Bad?" onChange={onChange}>
          <InputRadio value="Yes" label="Yes" />
          <InputRadio value="No" label="No" />
        </InputRadioGroup>
      </Grid>
      <Box component={Grid} item xs={12} display={!(transaction.data.CableSupportConditions.SupportSystemBad === 'Yes') ? 'none' : 'flex'}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <InputRadioGroup name="CableSupportConditions.TeakBlocksBad" value={transaction.data.CableSupportConditions.TeakBlocksBad || ''} label="Teak Blocks Bad?" onChange={onChange}>
              <InputRadio value="No Teak Blocks" label="No Teak Blocks" />
              <InputRadio value="Bad Teak Blocks" label="Bad Teak Blocks" />
              <InputRadio value="Good" label="Good" />
            </InputRadioGroup>
          </Grid>
          <Grid item xs={6}>
            <InputNumber name="CableSupportConditions.NumberOfBadTeakBlocks" value={transaction.data.CableSupportConditions.NumberOfBadTeakBlocks} label="# of Bad Teak Blocks" onChange={onChange} hidden={!(transaction.data.CableSupportConditions.TeakBlocksBad === 'Bad Teak Blocks')} />
          </Grid>
          <Grid item xs={6}>
            <InputRadioGroup name="CableSupportConditions.TurnbucklePresent" value={transaction.data.CableSupportConditions.TurnbucklePresent || ''} label="Turnbuckle Present?" onChange={onChange}>
              <InputRadio value="No Turnbuckle" label="No Turnbuckle" />
              <InputRadio value="Bad Turnbuckle" label="Bad Turnbuckle" />
              <InputRadio value="Good" label="Good" />
            </InputRadioGroup>
          </Grid>
          <Grid item xs={6}>
            <InputNumber name="CableSupportConditions.NumberOfBadTurnbuckles" value={transaction.data.CableSupportConditions.NumberOfBadTurnbuckles} label="# of Bad Turnbuckles" onChange={onChange} hidden={!(transaction.data.CableSupportConditions.TurnbucklePresent === 'Bad Turnbuckle')} />
          </Grid>
          <Grid item xs={6}>
            <InputRadioGroup name="CableSupportConditions.UprightsBad" value={transaction.data.CableSupportConditions.UprightsBad || ''} label="Are the Uprights Bad?" onChange={onChange}>
              <InputRadio value="Yes" label="Yes" />
              <InputRadio value="No" label="No" />
            </InputRadioGroup>
          </Grid>
          <Grid item xs={6}>
            <InputNumber name="CableSupportConditions.NumberOfBadUprights" value={transaction.data.CableSupportConditions.NumberOfBadUprights} label="# of Bad Uprights" onChange={onChange} hidden={!(transaction.data.CableSupportConditions.UprightsBad === 'Yes')} />
          </Grid>

          <Grid item xs={6}>
            <InputRadioGroup name="CableSupportConditions.ArmsBad" value={transaction.data.CableSupportConditions.ArmsBad || ''} label="Are the Arms Bad?" onChange={onChange}>
              <InputRadio value="Yes" label="Yes" />
              <InputRadio value="No" label="No" />
            </InputRadioGroup>
          </Grid>
          <Grid item xs={6}>
            <InputNumber name="CableSupportConditions.NumberOfBadArms" value={transaction.data.CableSupportConditions.NumberOfBadArms} label="# of Bad Arms" onChange={onChange} hidden={!(transaction.data.CableSupportConditions.ArmsBad === 'Yes')} />
          </Grid>

          <Grid item xs={6}>
            <InputRadioGroup name="CableSupportConditions.SaddlesBad" value={transaction.data.CableSupportConditions.SaddlesBad || ''} label="Are the Saddles Bad?" onChange={onChange}>
              <InputRadio value="Yes" label="Yes" />
              <InputRadio value="No" label="No" />
            </InputRadioGroup>
          </Grid>
          <Grid item xs={6}>
            <InputNumber name="CableSupportConditions.NumberOfBadSaddles" value={transaction.data.CableSupportConditions.NumberOfBadSaddles} label="# of Bad Saddles" onChange={onChange} hidden={!(transaction.data.CableSupportConditions.SaddlesBad === 'Yes')} />
          </Grid>

          <Grid item xs={12}>
            <InputRadioGroup name="CableSupportConditions.RackingRequired" value={transaction.data.CableSupportConditions.RackingRequired || ''} label="Racking Required?" onChange={onChange}>
              <InputRadio value="Off Support" label="Off Support" />
              <InputRadio value="On The Floor" label="On The Floor" />
              <InputRadio value="No" label="No" />
            </InputRadioGroup>
          </Grid>
        </Grid>
      </Box>
      <Grid item xs={12}>
        <InputTextarea name="CableSupportConditions.CableSupportComments" value={transaction.data.CableSupportConditions.CableSupportComments} label="Cable Supports Comments" onChange={onChange} />
      </Grid>
    </Grid>

  );
};

TransmissionHoleCableSupport.propTypes = {
  transaction: PropTypes.objectOf(PropTypes.any),
  onChange: PropTypes.func
};
TransmissionHoleCableSupport.defaultProps = {
  transaction: {},
  onChange: () => { }
};

export default TransmissionHoleCableSupport;
