import React from 'react';
import PropTypes from 'prop-types';
import {
  AccordionDetails,
  Accordion,
  AccordionSummary,
  Grid,
  makeStyles
} from '@material-ui/core';
import {
  InputRadio, InputRadioGroup, InputCheckbox, InputCheckboxGroup, InputTextarea, InputText
} from '@aldridge/aldg-data-components';

const TransmissionHoleLinkBox = (props) => {
  const { transaction, onChange } = props;
  const accordionClass = makeStyles({
    root: {
      border: '1px solid #ccc',
      '&:not(:last-child)': {
        borderBottom: 0,
      },
      '&$expanded': {
        border: '1px solid #2a56a7',
        margin: '8px 0'
      }
    },
    expanded: {}
  })();
  const accordionSummaryClass = makeStyles({
    root: {
      backgroundColor: '#eee',
      boxShadow: 'inset 0px -19px 4px -19px #aaa',
      '&$expanded': {
        maxHeight: '48px',
        minHeight: '48px',
        backgroundColor: 'rgb(59, 120, 231)',
        color: 'white',
        // border: '1px solid #ccc',
      }
    },
    expanded: {}
  })();
  const accordionDetailsClass = makeStyles({
    root: {
      flexDirection: 'column'
    },
  })();
  return (
    <Grid container spacing={3} direction="column">
      <Accordion classes={accordionClass}>
        <AccordionSummary classes={accordionSummaryClass}>
          Plumbing
        </AccordionSummary>
        <AccordionDetails classes={accordionDetailsClass}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <InputRadioGroup name="PlumbingConditions.PlumbingPresent" value={transaction.data.PlumbingConditions.PlumbingPresent || ''} label="Is Plumbing Present?" onChange={onChange}>
                <InputRadio value="Yes" label="Yes" />
                <InputRadio value="No" label="No" />
              </InputRadioGroup>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={3} hidden={!(transaction.data.PlumbingConditions.PlumbingPresent === 'Yes')}>
                <Grid item xs={12}>
                  <InputRadioGroup name="PlumbingConditions.PlumbingConditionBad" value={transaction.data.PlumbingConditions.PlumbingConditionBad || ''} label="Is the Plumbing Bad?" onChange={onChange}>
                    <InputRadio value="Yes" label="Yes" />
                    <InputRadio value="No" label="No" />
                  </InputRadioGroup>
                </Grid>
                <Grid item xs={6}>
                  <InputCheckboxGroup label="What's wrong with the Plumbing?" name="PlumbingConditions.PlumbingIssues" hidden={!(transaction.data.PlumbingConditions.PlumbingConditionBad === 'Yes')} onChange={onChange} value={transaction.data.PlumbingConditions.PlumbingIssues || ''}>
                    <InputCheckbox label="Pipe Bent" value="Pipe Bent" />
                    <InputCheckbox label="Pipe Leak" value="Pipe Leak" />
                    <InputCheckbox label="Pipe Kinked" value="Pipe Kinked" />
                  </InputCheckboxGroup>
                </Grid>
                <Grid item xs={6}>
                  <InputCheckboxGroup label="What's wrong with the Valve?" name="PlumbingConditions.ValveIssues" hidden={!(transaction.data.PlumbingConditions.PlumbingConditionBad === 'Yes')} onChange={onChange} value={transaction.data.PlumbingConditions.ValveIssues || ''}>
                    <InputCheckbox label="Valve Loose" value="Valve Loose" />
                    <InputCheckbox label="Valve Leaking" value="Valve Leaking" />
                    <InputCheckbox label="No Valve" value="No Valve" />
                  </InputCheckboxGroup>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <InputTextarea name="PlumbingConditions.PlumbingComments" value={transaction.data.PlumbingConditions.PlumbingComments} label="Plumbing Comments" onChange={onChange} />
              </Grid>
            </Grid>
          </Grid>

        </AccordionDetails>
      </Accordion>
      <Accordion classes={accordionClass}>
        <AccordionSummary classes={accordionSummaryClass}>
          Link Box
        </AccordionSummary>
        <AccordionDetails classes={accordionDetailsClass}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <InputText onChange={onChange} value={transaction.data.LinkBoxConditions.LinkBoxID || ''} name="LinkBoxConditions.LinkBoxID" label="LinkBox ID" />
            </Grid>
            <Grid item xs={12}>
              <InputRadioGroup name="LinkBoxConditions.LinkBoxDisconnected" value={transaction.data.LinkBoxConditions.LinkBoxDisconnected || ''} label="Link Disconnected" onChange={onChange}>
                <InputRadio value="Yes" label="Yes" />
                <InputRadio value="No" label="No" />
              </InputRadioGroup>
            </Grid>
            <Grid item xs={12}>
              <InputTextarea name="LinkBoxConditions.LinkBoxConditionsComments" value={transaction.data.LinkBoxConditions.LinkBoxConditionsComments} label="Link Box Comments" onChange={onChange} />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>

  );
};

TransmissionHoleLinkBox.propTypes = {
  transaction: PropTypes.objectOf(PropTypes.any),
  onChange: PropTypes.func
};
TransmissionHoleLinkBox.defaultProps = {
  transaction: {},
  onChange: () => { }
};

export default TransmissionHoleLinkBox;
